import React, { useRef, useState, useEffect, Suspense } from 'react';
import { Box, Grid, Hidden, Link } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useDetectPrint from "react-detect-print";
import { fetchPermalink } from '../core/API';
import { useParams, useSearchParams } from 'react-router-dom'

import {
  PC_HEADER_NATIVE,
  PC_CONTENT_NATIVE,
  PC_CONTENT_1_TOP,
  PC_CONTENT_1,
  PC_CONTENT_2,
  PC_RIGHT_STICKY,
  PC_FOOTER_STICKY,
} from '../components/ads/FuseAds';
import { useCalculator, DISPATCH_MERGE_DATA } from '../state/calculatorState'
import { useLayout, DISPATCH_REF } from '../state/layoutState'

import ComponentWrapper from '../components/common/ComponentWrapper'
import { Content, Fallback } from '../components/common/LayoutComponents';

import { STUDENT_LOAN_INDEXATION, STUDENT_LOAN_THRESHOLDS, RBA_INFLATION_OUTLOOK, RBA_INFLATION_HISTORIC, ATO_PERCENTILE_DISTRIBUTION, WAGE_PRICE_INDEX } from '../constants';
import { MAX_WIDTH } from '../constants';
import { useIsVisible } from '../utils/hooks'

import history from "../history";

//import IncomeComponent from '../components/calculator/income/Income';
// import { SummaryComponent } from '../components/calculator/summary/Summary';
// import { FAQ } from '../components/calculator/faq/FAQ';
// import { MoreInfo } from '../components/calculator/faq/MoreInfo';
// import Footer from '../components/footer/Footer';
// import { SalaryIntro } from '../components/calculator/income/SalaryIntro';
// import { IncomeTaxComponent } from '../components/info/IncomeTax'
// import { StudentLoanComponent } from '../components/studentLoan/StudentLoanComponent';
// import { RepaymentCalculator } from '../components/studentLoan/RepaymentCalculator'
// import { PercentileRangeComponent } from '../components/calculator/percentileRange/PercentileRange';
// import { DistributionComponentSampler } from '../components/calculator/distribution/Distribution';
// import { InflationCalculator } from '../components/inflation/InflationCalculator'
// import { InflationChart } from '../components/inflation/InflationChart'
// import { ChildCareSubsidyCalculator } from '../components/childcare/ChildCareSubsidyCalculator'

const IncomeComponent = React.lazy(() => import('../components/calculator/income/Income'));
const SummaryComponent = React.lazy(() => import('../components/calculator/summary/Summary'));
const FAQ = React.lazy(() => import('../components/calculator/faq/FAQ'));
const MoreInfo = React.lazy(() => import('../components/calculator/faq/MoreInfo'));
const Footer = React.lazy(() => import('../components/footer/Footer'));
//const SalaryIntro = React.lazy(() => import('../components/calculator/income/SalaryIntro'));
const IncomeTaxComponent = React.lazy(() => import('../components/info/IncomeTax'));
const StudentLoanComponent = React.lazy(() => import('../components/studentLoan/StudentLoanComponent'));
const RepaymentCalculator = React.lazy(() => import('../components/studentLoan/RepaymentCalculator'));
const PercentileRangeComponent = React.lazy(() => import('../components/calculator/percentileRange/PercentileRange'));
const DistributionComponentSampler = React.lazy(() => import('../components/calculator/distribution/Distribution'));
const InflationCalculator = React.lazy(() => import('../components/inflation/InflationCalculator'));
const InflationChart = React.lazy(() => import('../components/inflation/InflationChart'));
const ChildCareSubsidyCalculator = React.lazy(() => import('../components/childcare/ChildCareSubsidyCalculator'));


const useStyles = makeStyles(theme => (
  {
    contentWrapper: {
      backgroundColor: theme.palette.background.default,
      padding: 0,
    },
    feature: {
      paddingBottom: 10,
    }
  }
));


let hasSeenSummary = false
let hasSeenFAQ = false
let hasSeenIncomeTax = false
let hasSeenInflation = false
let hasSeenInflationCalculator = false
let hasSeenPercentile = false
let hasSeenStudentLoan = false
let hasSeenStudentLoanRepaymentMobile = false
let hasSeenCCS = false
let hasSeenMoreInfo = false



const CalculatorPage = (props) => {

  let [, dispatch] = useCalculator();
  const [{ inflationCalculatorRef, ccsCalculatorRef, printing }, dispatchLayout] = useLayout();

  // const [redrawCount, setRedrawCount] = useState(0);
  const [permalink, setPermalink] = useState(false);

  const calculatorRef = useRef(null);
  const inflationRef = useRef(null);
  const ccsRef = useRef(null);
  const classes = useStyles();
  const theme = useTheme();


  //LAZY RENDERING
  const refSummary = useRef();
  const refSummaryVisible = useIsVisible(refSummary);
  hasSeenSummary = hasSeenSummary || refSummaryVisible

  const refFAQ = useRef();
  const refFAQVisible = useIsVisible(refFAQ);
  hasSeenFAQ = hasSeenFAQ || refFAQVisible

  const refIncomeTax = useRef();
  const refIncomeTaxVisible = useIsVisible(refIncomeTax);
  hasSeenIncomeTax = hasSeenIncomeTax || refIncomeTaxVisible

  const refInflation = useRef();
  const refInflationVisible = useIsVisible(refInflation);
  hasSeenInflation = hasSeenInflation || refInflationVisible

  const refInflationCalculator = useRef();
  const refInflationCalculatorVisible = useIsVisible(refInflationCalculator);
  hasSeenInflationCalculator = hasSeenInflationCalculator || refInflationCalculatorVisible

  const refPercentile = useRef();
  const refPercentileVisible = useIsVisible(refPercentile);
  hasSeenPercentile = hasSeenPercentile || refPercentileVisible

  const refStudentLoan = useRef();
  const refStudentLoanVisible = useIsVisible(refStudentLoan);
  hasSeenStudentLoan = hasSeenStudentLoan || refStudentLoanVisible

  const refStudentLoanRepaymentMobile = useRef();
  const refStudentLoanRepaymentMobileVisible = useIsVisible(refStudentLoanRepaymentMobile);
  hasSeenStudentLoanRepaymentMobile = hasSeenStudentLoanRepaymentMobile || refStudentLoanRepaymentMobileVisible

  const refCCS = useRef();
  const refCCSVisible = useIsVisible(refCCS);
  hasSeenCCS = hasSeenCCS || refCCSVisible

  const refMoreInfo = useRef();
  const refMoreInfoVisible = useIsVisible(refMoreInfo);
  hasSeenMoreInfo = hasSeenMoreInfo || refMoreInfoVisible


  useEffect(() => {
    fetchPermalink(permalink).then((data) => {
      dispatch({ type: DISPATCH_MERGE_DATA, data });
    })
  }, [permalink, dispatch]);


  useEffect(() => {
    if (!inflationCalculatorRef && inflationRef.current) {
      dispatchLayout({
        type: DISPATCH_REF,
        data: { "inflationCalculatorRef": inflationRef.current },
      })
    }

    if (!ccsCalculatorRef && ccsRef.current) {
      dispatchLayout({
        type: DISPATCH_REF,
        data: { "ccsCalculatorRef": ccsRef.current },
      })
    }
  });


  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const xl = useMediaQuery(theme.breakpoints.only('xl'));

  let current_breakpoint = xs ? "xs" : sm ? "sm" : md ? "md" : lg ? "lg" : xl ? "xl" : false;

  // ads don't re-render when the breakpoint change
  // this will force a re-render!!
  const [breakpoint, setBreakpoint] = useState(current_breakpoint);
  const [key, setKey] = useState('');
  let refreshTimer;
  let detectPrint = useDetectPrint()
  if (current_breakpoint !== false && breakpoint !== current_breakpoint) {
    setBreakpoint(current_breakpoint);
    if (breakpoint !== false) {
      setKey(`${current_breakpoint}-${Date.now()}`);
      // clearTimeout(refreshTimer);
      // refreshTimer = setTimeout(() => {
      //   if (!detectPrint) {
      //     window.location.reload(false);
      //   }
      // }, 100);
    }
  }



  // Permalink
  let { id } = useParams();
  let [searchParams] = useSearchParams();

  if (id || searchParams.get('p')) {
    let newPermalink = id ? id : searchParams.get('p')
    if (newPermalink !== undefined) {
      if (newPermalink !== permalink) {
        setPermalink(newPermalink);
      } else {
        // clear the link param
        history.replace()
      }
    }
  }
  

  // test data
  //if(permalink !== "yHPdgs") setPermalink("yHPdgs")
  // if(permalink !== "zMq0wy") setPermalink("zMq0wy")


  const mobile = xs;
  const tablet = sm;
  const desktop = md || lg || xl;

  let padding = 15;
  let bannerWidth = 0;
  if (tablet) bannerWidth = 160 + padding * 2;
  if (desktop) bannerWidth = 300 + padding * 3;

  const paddingH = (mobile || tablet) ? 0 : 10;

  const spacing = xl ? 1 : 0;
  const spacingPadding = xl ? 10 : 10;


  return (
    <React.Fragment>
      {printing ? (
        <>
          <Box style={{ height: 90 }} ></Box>
          <SummaryComponent />
        </>
      ) : (

        <>
          <Box style={{ height: 100 }} />

          <Hidden lgDown>

            <Box style={{ width: xl ? `calc(100vw - ${bannerWidth}px)` : `100%` }}>
              <PC_HEADER_NATIVE keyAttribute={`PC_HEADER_NATIVE-${key}`} />
            </Box>
          </Hidden>
          <Box display="flex" justifyContent="flex-start">

            <div className={classes.contentWrapper} style={{ width: `calc(100vw - ${bannerWidth}px)`, maxWidth: MAX_WIDTH, paddingLeft: paddingH }}>
              <Grid container spacing={spacing} height="100%" >
                <Grid item xs={12} xl={5} height="100%" >
                  <Box display="flex" flexDirection="column" height="100%" >
                    {/* <Box style={{ paddingBottom: spacingPadding }} >
                  <Suspense fallback={<Fallback />}>
                    <SalaryIntro />
                  </Suspense>


                </Box> */}
                    <Box style={{ paddingBottom: spacingPadding }} id="input" display="flex" flexGrow={1}>
                      <Suspense fallback={<Fallback />}>
                        <IncomeComponent />
                      </Suspense>
                    </Box>
                  </Box>



                </Grid>
                <Hidden xlUp>
                  <Grid item xs={12} height="100%" >
                    <Box ref={calculatorRef} style={{ width: xl ? `calc(100vw - ${bannerWidth}px)` : `100%` }}>
                      <PC_CONTENT_NATIVE keyAttribute={`PC_CONTENT_NATIVE-${key}`}/>
                    </Box>
                  </Grid>
                </Hidden>

                <Grid item xs={12} xl={7} height="100%" >
                  <Box ref={refSummary} style={{ paddingBottom: spacingPadding }} height="100%" >
                    {hasSeenSummary && (
                      <Suspense fallback={<Fallback />}>
                        <SummaryComponent />
                        {/* <SummaryPrintable /> */}
                      </Suspense>
                    )}
                  </Box>
                </Grid>

                {/* <AdUnit XL={mobile || tablet || xl} padding={spacingPadding} /> */}
                <AdUnitInterscroll padding={spacingPadding} keyAttribute={`AdUnitInterscroll-${key}`} />
              </Grid>

              <Box ref={refFAQ} style={{}} >
                {hasSeenFAQ && (
                  <Suspense fallback={<Fallback />}>
                    <FAQ />
                  </Suspense>
                )}
              </Box>

              <AdUnit XL={mobile || tablet || xl} padding={spacingPadding} keyAttribute={`AdUnit-${key}`}/>

              <Grid container spacing={spacing} >
                <Grid item xs={12} xl={6} style={{ display: "flex", flexGrow: 1, marginBottom: spacingPadding }}>

                  <ComponentWrapper title="INCOME TAX">
                    <Content>
                      Australian income is levied at progressive tax rates. Tax bracket start at 0%, known as the tax-free rate, and increases progressively up to 45% for incomes over $180,000. In addition to income tax, there are additional levies such as Medicare. Individuals on incomes below $18,200 are also entitled to the Low and Middle Income Tax Offset (LMITO). Tax rates vary depending on residential status.
                      <br />
                      Use the table below to see how the current tax rates are calculated.

                    </Content>
                    <Box ref={refIncomeTax} style={{ marginBottom: spacingPadding }}>
                      {hasSeenIncomeTax && (
                        <Suspense fallback={<Fallback />}>
                          <IncomeTaxComponent />
                        </Suspense>
                      )}
                    </Box>
                  </ComponentWrapper>
                </Grid>

                <Grid item xs={12} xl={6} style={{ position: "relative", display: "flex", flexGrow: 1, marginBottom: spacingPadding }}>
                  <Box style={{ position: "absolute", top: -40, left: 0 }} ref={inflationRef}  ></Box>
                  <ComponentWrapper title="INFLATION CALCULATOR" >
                    <Content>
                      The drivers of the economy are great and varied, however a useful measure of how the economy affects living standards is inflation, and specifically the consumer price index. Economic inflation has an effect on the cost of living such that when inflation increases the cost of consumer goods and services also increases. In order to balance income with living costs, income also needs to change to absorb any rise. Inflation data has been sourced from <Link href={RBA_INFLATION_OUTLOOK} rel="noopener noreferrer" target="_blank" title="Reserve Bank">RBA Economic outlook</Link> and <Link href={RBA_INFLATION_HISTORIC} rel="noopener noreferrer" target="_blank" title="Reserve Bank">historic CPI Inflation</Link>. By comparison, the <Link href={WAGE_PRICE_INDEX} rel="noopener noreferrer" target="_blank" title="Wage price index">Wage Price Index</Link> is included in order to approximate the apparent difference between wages and cost of living.
                    </Content>

                    <Box ref={refInflation} style={{ marginBottom: spacingPadding }}>
                      {hasSeenInflation && (
                        <Suspense fallback={<Fallback />}>
                          <InflationChart />
                        </Suspense>
                      )}
                    </Box>
                    <Content>
                      Use this calculator to see the impact of inflation on your pay in real terms. Input the date of your last pay rise, and find out how your salary has been affected by inflation.
                    </Content>
                    <Box ref={refInflationCalculator} style={{ marginBottom: spacingPadding }}>
                      {hasSeenInflationCalculator && (
                        <Suspense fallback={<Fallback />}>
                          <InflationCalculator />
                        </Suspense>
                      )}
                    </Box>
                  </ComponentWrapper>


                </Grid>


                <Grid item xs={12} xl={6} style={{ display: "flex", flexGrow: 1, marginBottom: spacingPadding }} >

                  <ComponentWrapper title="INCOME RANGE">
                    <Content>
                      This chart shows how your income compares to that of other Australians. The data presented in this chart is derived from the <Link href={ATO_PERCENTILE_DISTRIBUTION} rel="noopener noreferrer" target="_blank" >ATO percentile distribution dataset</Link>. The graph illustrates the changes in incomes over a decade and provids an interesting perspective on the personal income landscape in Australia. The percentile range divides the population into 100 equally sized groups based on income, and additionally segmenting each percentile by gender ratio.

                    </Content>
                    <Box ref={refPercentile} style={{ marginBottom: spacingPadding }}>
                      {hasSeenPercentile && (
                        <Suspense fallback={<Fallback />}>
                          <PercentileRangeComponent />
                        </Suspense>
                      )}
                    </Box>
                    {hasSeenPercentile && (
                      <Suspense fallback={<Fallback />}>
                        <DistributionComponentSampler />
                      </Suspense>
                    )}
                  </ComponentWrapper>
                </Grid>

                <Grid item xs={12} xl={6}>
                  <ComponentWrapper title="STUDENT LOANS">
                    <Content>
                      Repayment thresholds and rates for all student loans including HELP, HECS, VET, SSL, ABSTUDY, TSL and SFSS are updated annually. Since July 2019 all types of student loan and the Student Financial Supplement Scheme (SFSS) have been consolidated under the same repayment threshold. Use the chart below to see how your salary effects you loan repayments. Refer to the ATO for a <Link href={STUDENT_LOAN_THRESHOLDS} rel="noopener noreferrer" target="_blank" title="Studen loan repayment thresholds">table of thresholds and rates</Link>.
                    </Content>
                    <Content>
                      Student loans undergo annual indexing to preserve their value relative to the consumer price index. Normally, this indexing rate is around 2%; however, in 2023, it increased to 7.1%. Refer to the <Link href={STUDENT_LOAN_INDEXATION} rel="noopener noreferrer" target="_blank" title="Studen loan indexation">current indexation rates</Link> to see how these rates are applied. Additionally, repayment thresholds are also adjusted to align with inflation.
                    </Content>
                    <Box ref={refStudentLoan} style={{ marginBottom: spacingPadding }}>
                      {hasSeenStudentLoan && (
                        <Suspense fallback={<Fallback />}>
                          <StudentLoanComponent />
                        </Suspense>
                      )}
                    </Box>

                    {xl && (
                      <ComponentWrapper title="Student Loan Repayment Calculator">
                        <Content>
                          Use this tool to calculate the estimated duration required to repay your loan. Simply input your current loan amount as it stands today.
                        </Content>
                        <Box style={{ marginBottom: spacingPadding }}>
                          {hasSeenStudentLoan && (
                            <Suspense fallback={<Fallback />}>
                              <RepaymentCalculator />
                            </Suspense>
                          )}
                        </Box>
                      </ComponentWrapper>

                    )}
                  </ComponentWrapper>
                </Grid>
                {!xl && (
                  <Grid item xs={12} xl={6} style={{ display: "flex", flexGrow: 1, marginBottom: spacingPadding }}>
                    <ComponentWrapper title="Student Loan Repayment Calculator">
                      <Content>
                        Use this tool to calculate how long it will take to repay your loan. Input your current loan amount as it is today.
                      </Content>
                      <Box ref={refStudentLoanRepaymentMobile} style={{ marginBottom: spacingPadding }}>
                        {hasSeenStudentLoanRepaymentMobile && (
                          <Suspense fallback={<Fallback />}>
                            <RepaymentCalculator />
                          </Suspense>
                        )}
                      </Box>
                    </ComponentWrapper>
                  </Grid>
                )}


                <Grid item xs={12} xl={6} style={{ position: "relative", display: "flex", flexGrow: 1, marginBottom: spacingPadding }}>
                  <Box style={{ position: "absolute", top: -40, left: 0 }} ref={ccsRef}  ></Box>
                  <ComponentWrapper title="Child Care Subsidy" >
                    <Content>
                      The Child Care Subsidy (CCS) is accessible to eligible parents based on their income level and the number of children under their care, subject to specific eligibility conditions. Payments under the subsidy are directly made to the child care provider.
                    </Content>
                    <Box ref={refCCS} style={{ marginBottom: spacingPadding }}>
                      {hasSeenCCS && (
                        <Suspense fallback={<Fallback />}>
                          <ChildCareSubsidyCalculator />
                        </Suspense>
                      )}
                    </Box>
                  </ComponentWrapper>
                </Grid>


                <AdUnit XL={mobile || tablet || xl} padding={spacingPadding} keyAttribute={`AdUnit-${key}`}/>

                <Grid item xs={12} height="100%" >

                  <Box ref={refMoreInfo} style={{ paddingBottom: spacingPadding }} >
                    {hasSeenMoreInfo && (
                      <Suspense fallback={<Fallback />}>
                        <MoreInfo />
                      </Suspense>
                    )}
                  </Box>
                </Grid>

              </Grid>




            </div>

            {!mobile && <PC_RIGHT_STICKY keyAttribute={`PC_RIGHT_STICKY-${key}`} />}

          </Box>
          {mobile && <PC_FOOTER_STICKY keyAttribute={`PC_FOOTER_STICKY-${key}`} />}
          {/* {!mobile && <PC_OUTSTREAM_VIDEO />} */}
          <Suspense fallback={<Fallback />}>
            <Footer />
          </Suspense>
        </>
      )}
    </React.Fragment>
  )
}



const AdUnit = (props) => {
  const { XL, spacingPadding, keyAttribute } = props;
  return (
    <Grid item xs={12} height="100%" >
      <Box style={{ paddingTop: spacingPadding, width: `100%` }} >
        {XL ? (
          <PC_CONTENT_1 keyAttribute={keyAttribute} />
        ) : (
          <PC_CONTENT_2 keyAttribute={keyAttribute} />
        )}
      </Box>
    </Grid>
  )

}

const AdUnitInterscroll = (props) => {
  const { spacingPadding, keyAttribute } = props;
  return (
    <Grid item xs={12} height="100%" >
      <Box style={{ paddingTop: spacingPadding, width: `100%` }} >
        <PC_CONTENT_1_TOP keyAttribute={keyAttribute} />
      </Box>
    </Grid>
  )

}


export default CalculatorPage;
