import { useState, useEffect, useRef } from 'react';


export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}



const OPTIONS = {
  root: null,
  rootMargin: "0px 0px 0px 0px",
  threshold: 0,
};

// Hook to set isVisible on an element when it is in the viewport
export const useIsVisible = (elementRef) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (elementRef.current) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(elementRef.current);
          }
        });
      }, OPTIONS);
      observer.observe(elementRef.current);
    }
  }, [elementRef]);

  return isVisible;
};



// image status
export const ImageStatus = {
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
}


// image cache
const imageCache = new Map();

// Preload image hook
export const useImage = (src) => {

  const cachedImg = imageCache.get(src);
  const initialState = cachedImg ? ImageStatus.LOADED : ImageStatus.LOADING;
  const [status, setStatus] = useState(initialState);
  const mounted = useRef(false);

  useEffect(() => {
    if (!src || status === ImageStatus.LOADED) return;
    mounted.current = false;
    try {
      let image;
      (async () => {
        image = await loadImage(src)
        // if(!mounted.current) return;
        imageCache.set(src, image);
        setStatus(ImageStatus.LOADED);
      })()

    } catch (error) {
      if (!mounted.current) return;
      imageCache.delete(src);
      setStatus(ImageStatus.FAILED);
    }
    return () => {
      mounted.current = false;
    }
  }, [src, status]);
  return [status, cachedImg];
}

// loading image promise
export const loadImage = async (url) => {
  const image = new Image();

  return new Promise((resolve, reject) => {
    const loaded = (event) => {
      image.onload = null
      image.onerror = null
      image.onabort = null
      try { delete image.src }
      catch (e) { }
      resolve(event.target || event.srcElement)

    }
    const errored = (error) => {
      image.onload = null
      image.onerror = null
      image.onabort = null;
      try { delete image.src }
      catch (e) { }
      reject(error);
    }

    image.onload = loaded;
    image.onerror = errored;
    image.onabort = errored;

    image.src = url
  })
}




/*

export const useScrollThreshold = ({ threshold = 0 }) => {

  let [active, setActive] = useState(false);

  useEffect(() => {
    const updateScroll = () => {

      const scrollY = window.pageYOffset;
      const isActive = scrollY > threshold;

      if (active !== isActive) {
        setActive(isActive);
        // why is the setter method not working?
        active = isActive;
      }
    }


    const onScroll = () => {
      // window.requestAnimationFrame(updateScroll);
      updateScroll();

    };

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll);
  }, [threshold]);

  return active;
};
*/
