import React, { createContext, useContext, useReducer } from 'react'

import { taxDistribution } from '../core/taxDistribution';

const LOCAL_STORAGE_NAME = "layout_1.0"
export const EXPAND_RESULTS = "expandResults";
export const EXPAND_DISTRIBUTION = "expandDistrubution";
export const EXPAND_ADVANCED_INPUT = "expandAdvancedInput";
export const EXPAND_ALL_INPUT = "expandAllInput";
export const UPDATE_DISTRIBUTION = "updateDistributionTable";
export const UPDATE_LAYOUT = "updateLayout";
export const SOFT_UPDATE_LAYOUT = "softUpdateLayout";
export const UPDATE_CALCULATION = "updateCalculation";
export const RESET = "reset_all";
export const START_PRINTING = "startPrinting";
export const END_PRINTING = "endPrinting";

export const DISPATCH_REF = "setRef";

export const LayoutContext = createContext();

export const InitialLayout = {

    expandInput_Superannuation: false,
    expandInput_NovatedLease: false,
    expandInput_FringeBenefits: false,
    expandInput_TaxCategory: false,
    expandInput_MedicareOffsets: false,
    expandInput_FamilyBenefits:false,
    expandInput_Deductions: false,

    printing:false,
    

    //expandResults: true,
    expandDistribution: false,
    expandAdvancedInput: false,
    expandIncome: false,
    expandSuper: false,
    expandTax: true,
    expandTaxReturn: false,
    expandNotes:false,
    
    animationCount: 0,
    resultsRange: 0,
    ytd: false,
    distributionTable: null, // reference of which items are open
    calculationCount: 0,

    // NON STRINGABLE ( remove from save data payload)
    inflationCalculatorRef:null,
    ccsCalculatorRef:null,
}

// local flag to indicate if the range has been manually adjusted - if it has been adjusted, do not auto update it if the payOption changes
let overrideRange = false;

let tempPrintingState = {}

const getSubFunctions = (dataArray, obj) => {
    if (!dataArray || !Array.isArray(dataArray)) return false;
    dataArray.map(fn => {
        if (fn && fn.subFunctions) {
            obj[fn.label] = false;
            getSubFunctions(fn.subFunctions, obj);
        }
        return true;
    })
    return true;
}


export const readLayout = () => {
    let data = InitialLayout;
    if (typeof (Storage) !== "undefined") {
        try {
            data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME));

            const localData = localStorage.getItem(LOCAL_STORAGE_NAME);
            if (localData) {
                data = JSON.parse(localData);
            }
        }
        catch (error) { console.log(error) }

        data = { ...InitialLayout, ...data };
    }

    if (!data.distributionTable) {
        data = { ...data, distributionTable: initDistributionTable() };
    }
    // force the printing state to be false on reload
    if (data.printing) {
        data = { ...data, printing: false };
    }
    return data
}


const initDistributionTable = () => {
    const distributionTable_init = {};
    getSubFunctions(taxDistribution.functions, distributionTable_init);
    return { distributionTable: distributionTable_init };
}

export const saveLayout = (layoutData) => {
    // only save the theme type not the entire object
    let saveLayoutData = {...layoutData};
    if( saveLayoutData["inflationCalculatorRef"]) delete saveLayoutData.inflationCalculatorRef
    if( saveLayoutData["ccsCalculatorRef"]) delete saveLayoutData.ccsCalculatorRef
    if (typeof (Storage) !== "undefined") {
        return localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(saveLayoutData));
    }
    return;
}


// Reducer ations
export const layoutReducer = (state, action) => {
    let newState = {};

    switch (action.type) {
        case EXPAND_RESULTS:
            newState = { ...state, expandResults: action.expanded };
            saveLayout(newState);
            return newState;
        case EXPAND_DISTRIBUTION:
            newState = { ...state, expandDistribution: action.expanded };
            saveLayout(newState);
            return newState;

        case EXPAND_ADVANCED_INPUT:
            newState = { ...state, expandAdvancedInput: action.expanded };
            saveLayout(newState);
            return newState;

        case EXPAND_ALL_INPUT:
            newState = {
                ...state,
                expandInput_Superannuation: action.expanded,
                expandInput_NovatedLease: action.expanded,
                expandInput_FringeBenefits: action.expanded,
                expandInput_TaxCategory: action.expanded,
                expandInput_MedicareOffsets: action.expanded,
                expandInput_FamilyBenefits: action.expanded,
                expandInput_Deductions: action.expanded,
            }
            saveLayout(newState);
            return newState;

        case UPDATE_DISTRIBUTION:
            newState = { ...state, distributionTable: action.data };
            saveLayout(newState);
            return newState;

        case UPDATE_LAYOUT:
            overrideRange = true;
            newState = { ...state, ...action.data };
            saveLayout(newState);
            return newState;

        case SOFT_UPDATE_LAYOUT:
            if (!overrideRange) {
                newState = { ...state, ...action.data };
                saveLayout(newState);
                return newState;
            }
            return state;;

        case UPDATE_CALCULATION:
            newState = { ...state, calculationCount: state.calculationCount + 1 };
            saveLayout(newState);
            return newState;

        case START_PRINTING:

        tempPrintingState = {
            expandIncome: state.expandIncome,
            expandSuper: state.expandSuper,
            expandTax: state.expandTax,
            expandTaxReturn: state.expandTaxReturn,
        }

            newState = { ...state, ...{printing: true, expandIncome:true, expandSuper:true, expandTax:true, expandTaxReturn:true } };
            // other settings...
            saveLayout(newState);
            return newState;

        case END_PRINTING:
            newState = { ...state, ...{printing: false }, ...tempPrintingState};
            // revert other settings...
            saveLayout(newState);
            return newState;

        case RESET:
            newState = {
                ...state,
                ...InitialLayout,
                ...initDistributionTable(),
            };
            saveLayout(newState);
            return newState;

        case DISPATCH_REF:
            newState = { ...state, ...action.data };
            return newState;
        default:
            return state;
    }
};


// Wrapper for the Provider injection
export const LayoutProvider = ({ initialState, children }) => {
    return (
        <LayoutContext.Provider value={useReducer(layoutReducer, initialState)}>
            {children}
        </LayoutContext.Provider>
    )
};

// NOTE: A component calling useContext will always re-render when the context value changes. If re-rendering the component is expensive, you can optimize it by using memoization.
export const useLayout = () => useContext(LayoutContext);
