
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const InIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M17.7,19.4h-3.3c-0.3,0-0.5-0.2-0.5-0.5v-1.6c0-0.3,0.2-0.5,0.5-0.5h3.3c0.7,0,1.3-0.6,1.3-1.3V8.1
	c0-0.7-0.6-1.3-1.3-1.3h-3.3c-0.3,0-0.5-0.2-0.5-0.5V4.8c0-0.3,0.2-0.5,0.5-0.5h3.3c2.1,0,3.8,1.7,3.8,3.8v7.5
	C21.5,17.7,19.8,19.4,17.7,19.4z M15.9,11.5L9.3,4.9C8.7,4.3,7.7,4.8,7.7,5.6v3.8H2.4c-0.5,0-0.9,0.4-0.9,0.9v3.8
	c0,0.5,0.4,0.9,0.9,0.9h5.3v3.8c0,0.8,1,1.3,1.6,0.7l6.6-6.6C16.3,12.5,16.3,11.9,15.9,11.5z"/>
    </SvgIcon>
  );
}


export const OutIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M21,12.6l-6.6,6.6c-0.6,0.6-1.6,0.2-1.6-0.7v-3.8H7.3c-0.5,0-0.9-0.4-0.9-0.9V10C6.4,9.5,6.8,9,7.3,9h5.4V5.3
	c0-0.8,1-1.3,1.6-0.7l6.6,6.6C21.3,11.6,21.3,12.2,21,12.6z M8.9,19v-1.6c0-0.3-0.2-0.5-0.5-0.5H5.1c-0.7,0-1.3-0.6-1.3-1.3V8.1
	c0-0.7,0.6-1.3,1.3-1.3h3.3c0.3,0,0.5-0.2,0.5-0.5V4.8c0-0.3-0.2-0.5-0.5-0.5H5.1C3,4.3,1.3,6,1.3,8.1v7.6c0,2.1,1.7,3.8,3.8,3.8
	h3.3C8.7,19.5,8.9,19.3,8.9,19z"/>
    </SvgIcon>
  );
}


export const EmailIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M18.5,3.3L2.7,12.4c-0.6,0.4-0.5,1.2,0.1,1.5l3.6,1.5l9.8-8.6c0.2-0.2,0.5,0.1,0.3,0.3l-8.2,10v2.7
	c0,0.8,1,1.1,1.4,0.5l2.2-2.6l4.2,1.8c0.5,0.2,1-0.1,1.1-0.6l2.4-14.7C19.8,3.5,19.1,3,18.5,3.3z"/>
    </SvgIcon>
  );
}



export const MMIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path class="st0" d="M11,43.6C-3.7,32,1.1,3.1,25.2,2.7c8.3,0.1,14.4,3.7,18.1,8.8" />
      <rect x="-11.8" y="-11.6" transform="matrix(0.766 -0.6428 0.6428 0.766 -10.3944 22.0558)" class="st2" width="73.9" height="73.9" />
    </SvgIcon>
  );
}


/*

  <?xml version="1.0" encoding="utf-8"?>
  <!-- Generator: Adobe Illustrator 26.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
  <style type="text/css">
    .st0{fill:#FFFFFF;}
    .st1{clip-path:url(#SVGID_00000013871383274385662490000014626432696790584492_);}
    .st2{fill:none;stroke:#FFFFFF;stroke-width:1.9785;stroke-miterlimit:10;}
  </style>
  <path class="st0" d="M11,43.6C-3.7,32,1.1,3.1,25.2,2.7c8.3,0.1,14.4,3.7,18.1,8.8"/>
  <g>
    <defs>
      <circle id="SVGID_1_" cx="25.1" cy="25.3" r="22.8"/>
    </defs>
    <clipPath id="SVGID_00000152233896320726864630000016451583329096366765_">
      <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
    </clipPath>
    <g style="clip-path:url(#SVGID_00000152233896320726864630000016451583329096366765_);">
      
        <rect x="-11.8" y="-11.6" transform="matrix(0.766 -0.6428 0.6428 0.766 -10.3944 22.0558)" class="st2" width="73.9" height="73.9"/>
    </g>
    <use xlink:href="#SVGID_1_"  style="overflow:visible;fill:none;stroke:#FFFFFF;stroke-width:1.9785;stroke-miterlimit:10;"/>
  </g>
  </svg>
  
*/

