import React, {useRef, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import ComponentHeader, { SectionHeader, ComponentHeaderMinimal } from './HeaderBar';

const useStyles = makeStyles(theme => (
    {
        bg: {
            backgroundColor: theme.palette.background.paper,
            marginBottom: 0,
            borderRadius: 10,
            //height:"100%",
            width: '100%',
        },

    }
));

const ComponentWrapper = (props) => {
    const classes = useStyles();
    if (props.minimal) {
        return (
            <div className={classes.bg}>
                <ComponentHeaderMinimal />
                {props.children}
            </div>
        )
    }
    return (
        <div className={classes.bg}>
            <ComponentHeader title={props.title} />
            {props.children}
        </div>
    )
}

export const SectionWrapper = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.bg}>
            <SectionHeader title={props.title} />
            {props.children}
        </div>
    )
}

export const SliderWrapper = (props) => {

    // const theme = useTheme();
    // const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    // const top = smallScreen ? 34 : 41;
    const top = 44;

    const sliderRef = useRef(null)

    useEffect(() => {
        if(sliderRef && sliderRef.current){
            let thumbs = sliderRef.current.getElementsByClassName("MuiSlider-thumb");
            if(thumbs && thumbs.length){
                for( let i = 0; i< thumbs.length; i++){
                    thumbs[i].setAttribute("tabindex", "-1")
                }
            }
     }
      });

    return (
        <Box ref={sliderRef} style={{ position: "absolute", top:top, left: 0, width: '100%', ...props.style }}>
            {props.children}
        </Box>
    )

}


export default ComponentWrapper;
