import React from 'react';
import ReactDOM from 'react-dom';
import {  BrowserRouter as Router } from 'react-router-dom';
import history from './history';
import './styles/index.css';
import App from './App';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
import MomentUtils from '@date-io/moment';

import ErrorBoundry from './utils/ErrorBoundary';

ReactDOM.render((
    <ErrorBoundry>
        <Router history={history}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <App />
            </MuiPickersUtilsProvider>
        </Router>
    </ErrorBoundry>
), document.getElementById('root'));
