import React from 'react'
import NumberFormat from 'react-number-format';


function parseValue( oldValue, newValue){
    // A string value of "0" is added to a field if it is undefined. This eliminates the zero from the new value
    // let newer = Number(newValue);
    // let parse = Number(oldValue) === 0 ? newer > 10 ? newer * 0.1 : newer: newer;
    return newValue
}

export const FloatFormat = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        ...other,
                        value: parseValue(other.value, values.value),

                    },
                });
            }}
            decimalScale={4}
            thousandSeparator
            isNumericString
        />
    );
}

export const PriceFormat = (props) => {
    const { inputRef, onChange, ...other } = props;
    if (!onChange) {
        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                decimalScale={2}
                thousandSeparator
                // isNumericString
                fixedDecimalScale
                prefix="$"
            />
        )
    }

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        ...other,
                        value: parseValue(other.value, values.value),
                    },
                });
            }
            }
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
}

export const PriceIntegerFormat = (props) => {
    const { inputRef, onChange, ...other } = props;
    if (!onChange) {
        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                decimalScale={0}
                thousandSeparator
                prefix="$"
            />
        )
    }

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        ...other,
                        value: parseValue(other.value, values.value),
                    },
                });
            }
            }
            decimalScale={0}
            fixedDecimalScale
            thousandSeparator
            prefix="$"
        />
    );
}



export const IntegerFormat = (props) => {
    const { inputRef, onChange, ...other } = props;
    if (!onChange) {
        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                decimalScale={0}
                thousandSeparator
            />
        )
    }

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        ...other,
                        value: parseValue(other.value, values.value),
                    },
                });
            }
            }
            decimalScale={0}
            fixedDecimalScale
            thousandSeparator
        />
    );
}

export const PercentFormat = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        ...other,
                        value: parseValue(other.value, values.value),
                    },
                });
            }}
            decimalScale={2}
            isNumericString
            suffix="%"
        />
    );
}


export const MultiplierFormat = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        ...other,
                        value: parseValue(other.value, values.value),
                    },
                });
            }}
            isNumericString
            prefix="x"
        />
    );
}
