import React, { } from 'react'
import { Typography, Box, alpha } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CustomTinyButton } from '../../components/common/CustomComponents';
import { useCalculator, DISPATCH_RESET_SALARY} from '../../state/calculatorState';
import { useLayout, RESET} from '../../state/layoutState';

const useStyles = makeStyles(theme => (
    {
        header: {
            userSelect: "none",
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            // backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
            //backgroundImage: `linear-gradient(to bottom, ${alpha(theme.palette.background.overlay, 0.5)}, ${theme.palette.background.paper})`,
            color: theme.palette.primary.contrastText,
            borderRadius: '10px 10px 0px 0px',
            width: '100%',
            marginTop:0,
            paddingTop:10,
            height: 60,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign:'center',
        },

        sectionHeader: {
            userSelect: "none",
            position: 'relative',
            // backgroundColor: theme.palette.primary.main,
            
            backgroundImage: `linear-gradient(to bottom right, ${alpha(theme.palette.primary.light, 0.05)}, ${theme.palette.secondary.main})`,
            borderRadius: 10,
            color: theme.palette.primary.contrastText,
            width: '100%',
            height: 240,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },

        auxHeader: {
            userSelect: "none",
            position: 'relative',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            width: '100%',
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: "pointer",
        },
        auxWrapper: {
            display:'flex',
            color: theme.palette.primary.contrastText,
            flexGrow:1,
            paddingLeft:12,
            alignItems:"center",
        },
        label:{
            letterSpacing:-1,
            lineHeight: '14px',
            color: theme.palette.primary.contrastText,
            whiteSpace: 'nowrap',
        },
        tick: {
            position: "absolute",
            bottom: -6,
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: '8px solid',
            borderTopColor: theme.palette.primary.main,
        }
    }
));


const ComponentHeader = (props) => {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.header}>
                {/* <div className={classes.tick} /> */}
                <Typography variant='h1' >{props.title.toUpperCase()}</Typography>
            </div>
        </div>
    )
}

export const ComponentHeaderMinimal = () => {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.headerMinimal} style={{height:0}}>
            </div>
        </div>
    )
}


export const SectionHeader = (props) => {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.sectionHeader}>
                <Typography variant='h2' >{props.title.toUpperCase()}</Typography>
            </div>
        </div>
    )
}

export const AuxillerytHeader = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const color = theme.palette.background.overlay;
    const [, dispatchCalculator] = useCalculator();
    const [, dispatchLayout] = useLayout();
    

    const resetAll = (e) => {
        e.stopPropagation();
        return (
            dispatchCalculator({
                type: DISPATCH_RESET_SALARY,
                data: 0,
            }),
            dispatchLayout({
                type: RESET,
                data: 0,
            })
        )
    }

    if( props.isOpen){
        return (
            <div className={classes.auxHeader}  onClick={() => props.handleClose()} >
                <div className={classes.tick} />
                    <div className={classes.auxWrapper}>
                    <Box display="flex" justifyContent="space-between" flex={1} style={{paddingRight:10}}>
                        <Box display="flex" justifyContent="flex-start" alignItems="center">
                            <KeyboardArrowUp aria-label="hide options" style={{marginRight:6}} />
                            <CustomTinyButton variant="contained" onClick={() => props.handleClose()} >{props.title}</CustomTinyButton>
                            {/* <Typography variant='h2' className={classes.label} >{props.title}</Typography> */}
                            <Typography variant='body2' style={{marginLeft:15, lineHeight:0.95, color: theme.palette.primary.contrastText}}>{props.children}</Typography>
                            </Box>
                        <CustomTinyButton variant="contained"  onClick={(e) => resetAll(e)} >RESET</CustomTinyButton>
                    </Box>
                    </div>
            </div>
        )
    }

    return (
            <div className={classes.auxHeader} style={{backgroundColor:color}} onClick={() => props.handleOpen() }>
                {/* <div className={classes.tick} style={{borderTopColor:color}} /> */}
                    <div className={classes.auxWrapper}>
                    <Box display="flex" justifyContent="space-between" flex={1} style={{paddingRight:10}}>
                        <Box display="flex" justifyContent="flex-start" alignItems="center">
                    <KeyboardArrowDown aria-label="hide options" style={{marginRight:6}} />
                    <CustomTinyButton variant="contained" style={{backgroundColor:theme.palette.primary.main}} onClick={() => props.handleOpen() } >{props.title}</CustomTinyButton>
                    {/* <Typography variant='h2' className={classes.label} >{props.title}</Typography> */}
                    <Typography variant='body2' style={{marginLeft:15, lineHeight:0.95 }}>{props.children}</Typography>
</Box>
                    <CustomTinyButton variant="contained" onClick={(e) => resetAll(e)} >RESET</CustomTinyButton>
                    </Box>
                </div>
            </div>
    )
}

export default ComponentHeader;
