import { salarySacraficeOptions, payOptions, FREQUENCY, FREQUENCY_DEFAULTS, YEAR, MONTH, FORTNIGHT, WEEK } from '../core/calculatorConstants';
import { roundToNearestDollerPlus99, roundToNearestDollar } from '../core/calculatorUtils';
import moment from 'moment'

export const parseInputValue = (value) => {
    let cleanValue = isNaN(value) ? value : Math.abs(value);
    if (cleanValue === "-") cleanValue = '';
    if (cleanValue === ".") cleanValue = '0.';
    return cleanValue;
}



export const getPixelRatio = context => {
    const devicePixelRatio = window.devicePixelRatio || 1;
    const backingStoreRatio =
        context.backingStorePixelRatio ||
        context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio ||
        1;

    const ratio = (devicePixelRatio / backingStoreRatio) || 1;
    const scaled = (devicePixelRatio !== backingStoreRatio)

    return { ratio, scaled }
};

export const resizeCanvas = (canvas, context, minHeight) => {

    const { ratio, scaled } = getPixelRatio(context);

    canvas.style.width = '100%';
    canvas.style.height = '100%';

    let w = getComputedStyle(canvas)
        .getPropertyValue('width')
        .slice(0, -2);
    let h = getComputedStyle(canvas)
        .getPropertyValue('height')
        .slice(0, -2);

    w = Number(w);
    h = Number(h);

    if (minHeight) {
        // min  height
        h = Math.max(minHeight, h);
    }

    if (scaled) {
        // set the 'real' canvas size to the higher width/height
        canvas.width = w * ratio;
        canvas.height = h * ratio;

        // ...then scale it back down with CSS
        canvas.style.width = w + 'px';
        canvas.style.height = h + 'px';
    }
    else {
        // this is a normal 1:1 device; just scale it simply
        canvas.width = w;
        canvas.height = h;
        canvas.style.width = '';
        canvas.style.height = '';
    }

    return { ratio, scaled, width: w, height: h }
}


export const formatMoney = (number = 0, decPlaces = 2, decSep = '.', thouSep = ',') => {

    const decimal_places = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
    const number_value = Number(Number(number).toFixed(decimal_places));
    let formattedNumber = number_value.toLocaleString();

    formattedNumber = formattedNumber.split(",").join(thouSep)

    // deal with trailing zeros
    if (Number.isInteger(number_value) && decimal_places === 2) return formattedNumber.toString() + decSep + "00"
    else if (Number.isInteger(number_value * 10) && decimal_places === 2) return formattedNumber.toString() + "0"
    return formattedNumber.toString()
}


export const compileOptions = (data) => {
    const {
        adjustProRata,
        overtime,
        noSuperannuation,
        includesSuperannuation,
        superannuationRate,
        adjustSuperannuationRate,
        adjustSuperannuation,
        additionalSuper,
        //over65,
        adjustSalaryScaracfice,
        adjustSpouseSuper,
        spouseSuperAmount,
        adjustSuperannuationCarryForward,
        superannuationCarryForward,
        salarySacraficeIncomeAmount,
        salarySacraficeIncomeOption,
        salaryScaracficeAmount,
        salarySacraficeOption,
        capitalGains,
        medicareExemption,
        medicareExemptionValue,
        medicareAdjustment,
        medicareFamilyAdjustment,
        applyMedicareAdjustment,
        adjustDeductions,
        adjustAllowanceIncome,
        allowance,
        adjustFringeBenefits,
        taxableDeductions,
        fringeBenefits,
        adjustOtherIncome,
        otherIncome,
        businessIncome,
        taxCredits,
        frankingCredits,
        nonResident,
        haveTFN,
        backpacker,
        noTaxFree,
        HELP,
        SFSS,
        SAPTO,
        spouseSeparated,
        hasPrivateHealthcare,
        medicareSurcharge,
        dependants,
        dependantsCount,
        childSupport,
        spouse,
        spouseIncome,
        superannuationCoContributionAvailable,
        superannuationCoContributionApply,
        superannuationCoContribution,
        payments,
        ftb,
        hasBonus,
        bonusIncome,
        fulltime,
        novatedLeaseAmount,

        warnings
    } = data;

    // options text
    let options = []
    if (includesSuperannuation) options.push([`Includes Superannuation`, 'Super is taken out of your salary']);

    if (overtime && overtime.length > 0) options.push([`Overtime`])

    if (adjustProRata) options.push([`Pro-rata salary`]);

    if (!fulltime) options.push([`Casual hours`]);

    if (noSuperannuation) {
        options.push([`No Superannuation`, `Self employed aren't obliged to pay Superannuation `])
    } else {
        if (adjustSuperannuationRate) {
            // options.push([`Super rate ${superannuationRate}%`]);
            options.push([`Super Rate`, `${superannuationRate}%`]);
        }
    }


    if (adjustSuperannuation) {
        if (additionalSuper > 0) {
            //if (over65) options.push([`Extra Superannuation`, additionalSuper, "over 65"]);
            options.push([`Extra Superannuation`, additionalSuper]);
        }
    }
    if (adjustSuperannuation && superannuationCoContributionAvailable && superannuationCoContributionApply) options.push([`Super Co-contribution`, superannuationCoContribution]);


    if (adjustSalaryScaracfice) {
        if (salaryScaracficeAmount > 0) options.push([`Salary Sacrifice Super`, salaryScaracficeAmount, " per " + salarySacraficeOptions[salarySacraficeOption]]);
    }


    if (adjustSpouseSuper) options.push([`Spouse super contributions`, spouseSuperAmount]);

    if (adjustSuperannuationCarryForward && superannuationCarryForward > 0) options.push([`Carry forward super`, superannuationCarryForward]);


    //if (over65) options.push([`Over 65`]);

    if (nonResident) {
        if (haveTFN) options.push([`Non-resident`]);
        else options.push([`Non-resident`, `no tax file number`]);
    }

    if (backpacker) options.push([`Working holiday maker`]);
    if (!nonResident && !backpacker) {
        if (noTaxFree) options.push([`No Tax-free threshold`]);
        if (HELP) options.push([`Student Loan`]);
        if (SFSS) options.push([`SFSS`]);
        if (medicareExemption) options.push([`Medicare exemption`, (medicareExemptionValue === 1 ? "Full" : "Half")]);

        if (medicareFamilyAdjustment) {
            if (applyMedicareAdjustment) options.push([`Medicare adjustment`, medicareAdjustment.w]);
        }
    }
    if (medicareSurcharge > 0 && !hasPrivateHealthcare) options.push(['No private healthcare']);
    if (dependants && dependantsCount > 0) {
        options.push([`Dependants`, dependantsCount]);
        if (childSupport > 0) {
            options.push([`Child Support`, childSupport]);
        }
    }

    if (spouse && spouseIncome === 0) options.push([`Family Medicare`]);
    // if (spouse && spouseIncome > 0) options.push([`Spouse income`, spouseIncome]);

    if (SAPTO) {
        if (spouseSeparated) options.push([`SAPTO`, 'Spouse separated due to illness']);
        else options.push([`SAPTO`, dependantsCount]);
    }


    if (adjustDeductions) {
        if (taxableDeductions > 0) options.push([`Tax deductions`, taxableDeductions]);
    }


    if (salarySacraficeIncomeAmount > 0) {
        options.push([`Salary Sacrifice`, salarySacraficeIncomeAmount, " per " + salarySacraficeOptions[salarySacraficeIncomeOption]]);
    }

    if (adjustOtherIncome) {
        if (otherIncome > 0) options.push([`Other income`, otherIncome]);
    }

    if (businessIncome !== 0) options.push([`Business income`, businessIncome]);
    if (taxCredits !== 0) options.push([`Tax credits`, taxCredits]);
    if (frankingCredits !== 0) options.push([`Franking credits`, frankingCredits]);


    if (adjustAllowanceIncome) {
        if (allowance.a > 0) options.push([`Allowance`, allowance.a, "per year"]);
    }
    if (adjustFringeBenefits !== false) {
        if (fringeBenefits > 0) options.push([`Fringe benefits`, fringeBenefits]);
    }

    if (capitalGains !== 0) {
        if (capitalGains > 0) options.push([`Capital Gains`, capitalGains]);
        if (capitalGains < 0) options.push([`Capital Loss`, capitalGains]);
    }

    if (ftb.a > 0) {
        options.push([`FTB`, ftb.a]);
    }





    if (payments.w > 52) options.push([`${payments.w} weekly pay cycles`]);

    if (!warnings.superannuationCapped && warnings.maximunContributionsCapActive) {
        options.push([`Super excess`]);
    }

    if (hasBonus && bonusIncome > 0) options.push([`Bonus`]);

    if (novatedLeaseAmount && novatedLeaseAmount > 0) options.push([`Novated Lease`]);


    return options;
}



export const compileNotes = (calculator) => {
    let notes = []
    const {
        includesSuperannuation,
        listo,
        spouse,
        familyIncome,
        spouseSuperTaxOffset,
        adjustSuperannuationCarryForward,
        superannuationCarryForward,
        medicareSurcharge,
        adjustedAnnualTaxableIncome,
        lito,
        lamito,
        sapto,
        rebateIncome,
        warnings,
        payments,
        payOption,
        taxableDeductions,
        allowance,
        capitalGains,
        otherIncome,
        businessIncome,
        adjustSuperannuation,
        division293,
    } = calculator;


    // only warning if mobile && option is 2 or 3 ?
    if (warnings.extraPayment) {
        let note = `* Based on your pay day, there are ${payments.w} weekly or ${payments.f} fortnightly payments this year. `;
        switch (payOption) {
            case 0:
                note += `Given that your pay is annual, fortnightly and weekly amounts are reduced`;
                break;
            default:
                note += `You will pay more tax on your PAYG income if you are paid weekly of fortnightly.`;
                break;
        }
        notes.push(note);
    }

    if (includesSuperannuation) {
        notes.push(`Superannuation is part of your wage (salary package) and paid into your Superannuation fund.`);
    } else {
        notes.push(`Superannuation is paid by your employer into your fund in addition to your wage.`);
    }


    if (warnings.superannuation && warnings.superannuation.length > 0) {
        notes = notes.concat(warnings.superannuation);
    }

    notes.push(`Your Adjusted Taxable Income is $${formatMoney(adjustedAnnualTaxableIncome, 0)}`);

    if (medicareSurcharge > 0) {
        let note = `You may be liable to a Medicare surcharge of $${formatMoney(medicareSurcharge, 0)} per year if you do not have suitable private health insurance.`;
        if (spouse) {
            note = `${note} This is based on an adjusted family income of $${formatMoney(familyIncome, 0)}`;
        } else {
            note = `${note} This is based on an adjusted taxable income of $${formatMoney(adjustedAnnualTaxableIncome, 0)}`;
        }
        notes.push(note);

    }

    if (sapto > 0) {
        notes.push(`Senior Australian Pensioner Tax Offset (SAPTO) of up to $${formatMoney(sapto, 0)} based on a rebate income of $${formatMoney(rebateIncome, 0)}`)
    }

    if (lito > 0 && lamito > 0) {
        notes.push(`Low Income Tax Offset (LITO) of up to $${formatMoney(lito, 0)} and a Low And Middle Income Tax Offset (LMITO) of up to $${formatMoney(lamito, 0)}`)
    } else {
        if (lito > 0) {
            notes.push(`Low Income Tax Offset (LITO) of up to $${formatMoney(lito, 0)}`)
        }
        if (lamito > 0) {
            notes.push(`Low And Middle Income Tax Offset (LMITO) of up to $${formatMoney(lamito, 0)}`)
        }
    }

    if (spouseSuperTaxOffset > 0) {
        notes.push(`Tax Offset as you contributed to your spouses Superannuation fund of up to $${formatMoney(spouseSuperTaxOffset, 0)}`)
    }

    if (listo) {
        notes.push(`Low Income Superannuation Tax Offset (LISTO). This reduces your tax liability on your Superannuation by up to $${formatMoney(listo, 0)}`)
    }


    if (adjustSuperannuationCarryForward && superannuationCarryForward > 0) notes.push(`Carrying forward $${formatMoney(superannuationCarryForward, 0)} of unclaimed Superannuation`);


    // if ((adjustSuperannuation && additionalSuper > 0) ) {
    //         notes.push(`* Annual pay includes the $${formatMoney(additionalSuper, 0)} you contributed to your superannuation fund`)
    // }

    if (taxableDeductions !== 0 || allowance.a !== 0 || capitalGains !== 0 || otherIncome !== 0 || adjustSuperannuation || businessIncome !== 0) {
        notes.push(`* Annual pay includes all other income/loss, allowances, and capital gains. It doesn't include deductions or voluntary super.`)
    }


    if (!warnings.superannuationCapped && warnings.maximunContributionsCapActive) {
        notes.push(`Your Superannuation contributions are not capped to the Maximum Contribution Base`);
    }

    if (division293 > 0) {
        notes.push(`The Division 293 tax is an estimate and is calculated separately from income tax.`);
    }


    notes.push(`This calculator is an estimate.`);

    return notes;
}



export const equivalentAnnualConversion = (actual) => {
    let value = actual / 12;
    // round to nearest cent
    value = Math.round(value * 100) / 100;

    // if ending in .33 add 0.01
    if (getCents(value) === 33) value += 0.01;

    // dvide by 3 multiply by 13
    value = (value * 3) / 13;

    // round and add 99cents
    value = roundToNearestDollerPlus99(value);

    // value is weekly
    // -> annual
    value = (value * 13) / 3;
    value = roundToNearestDollar(value * 12);

    return value

}


export const getCents = (value) => {
    let val = Math.round(value * 100) / 100;
    let decimal = val.toString().split(".");
    if (decimal && decimal.length > 1) {
        return Number(decimal[1])
    }
    return 0;
}



export const matchPath = (a, b) => {
    const a_ = a.split("/").join("");
    const b_ = b.split("/").join("");
    return a_ === b_;
}

// move payDate so that is is between start and End date, move by weeks
export const resetDate = (date, start, end,) => {

    let skipDays = 7;

    // move date forward
    while (date <= start) {
        date.setDate(date.getDate() + skipDays);
    }

    // move date back
    if (date > end) {
        while (date >= start) {
            date.setDate(date.getDate() - skipDays);
        }
        date.setDate(date.getDate() + skipDays);
    }
    return date;

}


export const calculatePayments = (ytdFrom, ytdTo, year, payOption, ytd, casual, fulltime, unpaidLeave, unpaidPublicHolidays) => {

    // ordinry hours

    // only include unpaid if not fulltime and casual rate is not using annual days worked
    let unpaid = fulltime ? 0 : (casual.frequency === YEAR) ? 0 : Number(unpaidLeave) + Number(unpaidPublicHolidays);

    let daysPerYear = (5 * 52)
    if (!fulltime) {
        const frequency = casual.frequency;
        const days = casual.days;
        //export const FREQUENCY = [WEEK, FORTNIGHT, MONTH, YEAR];
        switch (frequency) {
            case WEEK:
                daysPerYear = days * 52 - unpaid // leap year??
                break
            case FORTNIGHT:
                daysPerYear = days * 27 - unpaid // leap year??
                break
            case MONTH:
                daysPerYear = days * 12 - unpaid
                break
            case YEAR:
                daysPerYear = days
                break
            default:
                break
        }
    }


    if (!ytd) {
        return {
            //fulltime
            //unpaidLeave
            //unpaidPublicHolidays
            // days depends on casual status
            payments: { d: daysPerYear, w: 52, f: 26, m: 12, a: 1 },
            YTD: { d: daysPerYear, w: 52, f: 26, m: 12, a: 1 }
        };
    }

    const firstDay = new Date(`${Number(year) - 1}-07-06`);
    const lastDay = new Date(`${Number(year)}-07-01`)


    // convert YTD dated to js Date obj
    let YTD_FROM = (typeof ytdFrom === "string" && ytdFrom !== "") ? new Date(ytdFrom) : firstDay
    let YTD_TO = (typeof ytdTo === "string" && ytdTo !== "") ? new Date(ytdTo) : lastDay


    // if the EOFY is in a year prior to YTD_TO then use the EOFY ( previous years will be YTD complete)
    YTD_TO = (YTD_TO > lastDay) ? lastDay : YTD_TO;

    // Rule: if this is not in the first month and is annual or montly pay options, then assume that this is a part year, otherwise, back date to start of year
    const backdate = false;// payOption <= 1; // annually or monthly

    // For daily YTD, reste the start of Inncial year to first day
    // It is otherwise set the the 6th day to avoid the leapyear extra payments occurring by default.


    let days = churnDates(YTD_FROM, firstDay, lastDay, 1, false, backdate);

    let daysWorkedPerWeek = 5;
    if (payOptions[payOption].casual) {

        const frequency = casual.frequency;
        const days = casual.days;
        //export const FREQUENCY = [WEEK, FORTNIGHT, MONTH, YEAR];
        switch (frequency) {
            case FREQUENCY[0]:
                daysWorkedPerWeek = days
                break;
            case FREQUENCY[1]:
                daysWorkedPerWeek = days / 2
                break;
            case FREQUENCY[2]:
                daysWorkedPerWeek = days / (52 / 12)
                break;
            default:
                daysWorkedPerWeek = days / 52;
                break;
        }
    }

    days = (days % 7 + Math.floor(days / 7) * (daysWorkedPerWeek)) - unpaid // total paid working days per year

    let daysYTD = churnDates(YTD_FROM, firstDay, YTD_TO, 7, false, backdate);
    // include unpaid
    daysYTD = Math.max(0, daysYTD - unpaid);
    daysYTD = daysYTD % 7 + Math.floor(daysYTD / 7) * (daysWorkedPerWeek);

    const weeks = churnDates(YTD_FROM, firstDay, lastDay, 7, false, backdate);
    const weeksYTD = churnDates(YTD_FROM, firstDay, YTD_TO, 7, false, backdate);

    const fortnights = churnDates(YTD_FROM, firstDay, lastDay, 14, false, backdate);
    const fortnightsYTD = churnDates(YTD_FROM, firstDay, YTD_TO, 14, false, backdate);

    const months = monthsBetweenDates(YTD_FROM, lastDay);
    const monthsYTD = churnDates(YTD_FROM, firstDay, YTD_TO, 1, true, false);

    // annual YTD - set the toDate to the end of the year. Based on the payOption set a fraction for full year
    // produced a number between 0 and 1 as a fraction of the year elapsed
    let YTD_A = 0;
    switch (payOption) {
        case 0:
        case 1:
            // use monthly
            YTD_A = months / 12;
            break;
        case 2:
            // fortnightly
            YTD_A = (fortnights > 26) ? fortnights / 27 : fortnights / 26;
            break;
        case 3:
        default:
            // weekly
            YTD_A = (weeks > 52) ? weeks / 53 : weeks / 52;
            break;
    }

    return { payments: { d: days, w: weeks, f: fortnights, m: months, a: 1 }, YTD: { d: daysYTD, w: weeksYTD, f: fortnightsYTD, m: monthsYTD, a: YTD_A } };
}


export const monthsBetweenDates = (a, b) => {
    let months = 0;
    months = (b.getFullYear() - a.getFullYear()) * 12;
    months -= a.getMonth();
    months += b.getMonth();
    return months <= 0 ? 0 : months;
}



export const churnDates = (date, firstDay, dateLimit, span, months, backdate, debug) => {
    // step start date to be today
    // 'startDate is the payday that is steppted through the year
    let startDate = new Date(date.getTime());
    if (startDate > dateLimit) {
        return 0;
    }

    // current month
    if (backdate && startDate.getMonth() === firstDay.getMonth()) {
        // Hack to make weekly and fortnightly payments look right if monthly or annually is selected 
        while (startDate >= firstDay) {
            startDate.setDate(startDate.getDate() - span);
        }
        startDate.setDate(startDate.getDate() + span);
    }

    let pays = 0;
    // this counts over the limit, but because it is zero indexed the number of pays is corrected
    while (startDate <= dateLimit) {
        pays++;
        if (months) {
            startDate.setMonth(startDate.getMonth() + span);
        } else {

            startDate.setDate(startDate.getDate() + span);
        }
        if (debug) {
            console.log("startDate:", startDate, pays)
        }
    }

    return pays;
}


export const churnDays = (date, firstDay, dateLimit, span) => {
    // step start date to be today
    // 'startDate is the payday that is steppted through the year
    let startDate = new Date(firstDay.getTime());
    if (startDate > dateLimit) {
        return 0;
    }

    // step back from today until it matches the first day
    let pays = 0;
    // this counts over the limit, but because it is zero indexed the number of pays is corrected
    while (startDate < dateLimit) {
        // skip weekends!!
        if (startDate.getDay() > 0 && startDate.getDay() < 6)
            pays++;
        startDate.setDate(startDate.getDate() + span);
    }

    return pays;
}


export const date2str = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}



export const formatDate = (date) => {
    const momentDate = date ? moment(date) : moment();
    return momentDate.format("dddd, MMMM Do YYYY");
}



//  USEAGE: switchFrequency("Week", "Month") -> 52/12 = 4


export const switchFrequency = (from, to) => {

    //  FREQUENCY = ["Week", "Fortnight", "Month", "Year"];
    // FREQUENCY_DEFAULTS = [52, 26, 12, 1];

    const fromIndex = Math.max(0, FREQUENCY.indexOf(from));
    const toIndex = Math.max(0, FREQUENCY.indexOf(to));

    let conversion = FREQUENCY_DEFAULTS[fromIndex] / FREQUENCY_DEFAULTS[toIndex];
    if (isNaN(conversion)) conversion = 1;

    return conversion;

}


export const defaultFrequencyValue = (freq) => {
    //  FREQUENCY = ["Week", "Fortnight", "Month", "Year"];
    // FREQUENCY_DEFAULTS = [52, 26, 12, 1];
    const freqIndex = Math.max(0, FREQUENCY.indexOf(freq));
    return FREQUENCY_DEFAULTS[freqIndex];

}


export const getFinancialYear = (date) => {
    // financial year starts on 1 / 6 / ( financial year - 1 )
    let year = date.getFullYear();
    let thisFY = new Date(year, 6, 1);
    if (date > thisFY) {
        return year;
    }
    return year - 1
}


export const ordinal = (num) => {
    let n = num % 10;
    if (n === 1) return "st"
    if (n === 2) return "nd"
    if (n === 3) return "rd"
    return "th";
}

// Precision refers to significant figures of precision
export const precision = (v, p = 1) => {
    const value = Number.parseFloat(v.toPrecision(p))
    return value
}

// Number of decimal places
export const precisionDP = (v, p = 1) => {
    const divisor = Math.pow(10, p)
    const value = Math.round((v + Number.EPSILON) * divisor) / divisor
    return value
}





/*
    blend two colors to create the color that is at the percentage away from the first color
    this is a 5 step process
        1: validate input
        2: convert input to 6 char hex
        3: convert hex to rgb
        4: take the percentage to create a ratio between the two colors
        5: convert blend to hex
    @param: color1      => the first color, hex (ie: #000000)
    @param: color2      => the second color, hex (ie: #ffffff)
    @param: percentage  => the distance from the first color, as a decimal between 0 and 1 (ie: 0.5)
    @returns: string    => the third color, hex, represenatation of the blend between color1 and color2 at the given percentage
*/
export const blendColors = (color1, color2, percentage) => 
{
    // check input
    color1 = color1 || '#000000';
    color2 = color2 || '#ffffff';
    percentage = percentage || 0.5;

    // 2: check to see if we need to convert 3 char hex to 6 char hex, else slice off hash
    //      the three character hex is just a representation of the 6 hex where each character is repeated
    //      ie: #060 => #006600 (green)
    if (color1.length == 4)
        color1 = color1[1] + color1[1] + color1[2] + color1[2] + color1[3] + color1[3];
    else
        color1 = color1.substring(1);
    if (color2.length == 4)
        color2 = color2[1] + color2[1] + color2[2] + color2[2] + color2[3] + color2[3];
    else
        color2 = color2.substring(1);   

    // 3: we have valid input, convert colors to rgb
    color1 = [parseInt(color1[0] + color1[1], 16), parseInt(color1[2] + color1[3], 16), parseInt(color1[4] + color1[5], 16)];
    color2 = [parseInt(color2[0] + color2[1], 16), parseInt(color2[2] + color2[3], 16), parseInt(color2[4] + color2[5], 16)];

    // 4: blend
    var color3 = [ 
        (1 - percentage) * color1[0] + percentage * color2[0], 
        (1 - percentage) * color1[1] + percentage * color2[1], 
        (1 - percentage) * color1[2] + percentage * color2[2]
    ];

    // 5: convert to hex
    color3 = '#' + int_to_hex(color3[0]) + int_to_hex(color3[1]) + int_to_hex(color3[2]);

    // return hex
    return color3;
}

/*
    convert a Number to a two character hex string
    must round, or we will end up with more digits than expected (2)
    note: can also result in single digit, which will need to be padded with a 0 to the left
    @param: num         => the number to conver to hex
    @returns: string    => the hex representation of the provided number
*/
function int_to_hex(num)
{
    var hex = Math.round(num).toString(16);
    if (hex.length == 1)
        hex = '0' + hex;
    return hex;
}