import React from 'react'
import {
    Box,
    useTheme,
    useMediaQuery,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { common } from '@material-ui/core/colors';

import { PriceFormat, PriceIntegerFormat } from '../common/customFormatters';
import { useCalculator, DISPATCH_ADJUSTMENT, DISPATCH_UPDATE_SALARY } from '../../state/calculatorState';
import { payOptions } from '../../core/calculatorConstants';
import { StyledTextInput, AdjustSalarySlider, SliderTooltip, CustomTinyButton } from '../common/CustomComponents';
import { parseInputValue } from '../../utils/utils'


const useStyles = makeStyles(theme => {
    return {

        wrap: {
            paddingLeft: 20,
            paddingRight: 0,
            paddingTop: 5,
            paddingBottom: 5,
            display: 'flex',
            flexDirection: "row",
            alignItems: "center",


            backgroundColor: theme.palette.primary.light,
            transition: theme.transitions.create(
                ['all'],
                { duration: theme.transitions.duration.shortest }
            ),
        },
        input: {
            width: 160,
        },
        label: {
            color: common.white,
        }
    }
})


function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
        <SliderTooltip open={open} title={
            <Typography variant="h4" style={{ fontSize: 12 }}> {PriceIntegerFormat({ value: value, displayType: "text" })} </Typography>
        } >
            {children}
        </SliderTooltip>
    );
}


const MiniInput = (props) => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const classes = useStyles();
    let [{ salary, payOption, adjustment }, dispatch] = useCalculator();

    const handleChangeSalary = () => event => {
        if(document.activeElement.className !== event.target.className) return;
        let newValue = parseInputValue(event.target.value);
        changeSalary(newValue);
    }

    const changeSalary = (value) => {
        const currentValue = salary;
        let newValue = value.toLocaleString('fullwide', { useGrouping: false })

        // if (currentValue === ZERO_VALUE) {
        //     newValue = (newValue - ZERO_VALUE) / 10;
        // }

        // newValue = newValue > 0 ? newValue : ZERO_VALUE;

        // skip this if the value hasn't changed
        if(Number(newValue) === Number(currentValue)) return;

        return (
            dispatch({
                type: DISPATCH_UPDATE_SALARY,
                payOption,
                salary:newValue
            })
        )
    }

    const handleAdjustment = (event) => {
        return (
            dispatch({
                type: DISPATCH_ADJUSTMENT,
                data: event.target.value,
            })
        )
    };

    const handleEditIncome = () => {
        // window.scrollTo(0, ref.current.offsetTop);
        const element = document.getElementById("input");
        window.scrollTo(0, element.offsetTop);

        return false;
    }


    const range = salary * 0.3;
    const showAdjuster = false;
    const paddingLeft = xs ? 0 : 10;
    const paddingRight = xs ? 5 : 22;
    const top = 0;//small ? NAV_HEIGHT_SMALL : NAV_HEIGHT;

    return (
        <Box className={classes.wrap} style={{ transform: props.active ? 'scaleY(1)' : 'scaleY(0.0)', position:'fixed', top:top, width:'100%', paddingRight:10, transformOrigin: '0% 0%'}}>
            <Typography id="Salary input" className={classes.label} style={{paddingRight:paddingRight, paddingLeft:paddingLeft}} variant="h3">{`Your Salary: `}</Typography>

            <StyledTextInput
                className={classes.input}
                label=""
                aria-label="mini salary input"
                value={ Number(salary) + Number(adjustment) > 0 ? Number(salary) + Number(adjustment) : null }
                variant="filled"
                type="text"
                InputProps={{
                    "aria-label":"Salary input",
                    inputComponent: PriceFormat,
                    inputMode: 'decimal',
                    inputProps: {
                        pattern: "[0-9].",
                        inputMode: 'decimal',
                    },
                }}
                onChange={handleChangeSalary()}
                id="minisalary"
            />
            <Box style={{ width: 10 }} />
            <Typography className={classes.label} variant="h3">{`per ${payOptions[payOption].tipLabel}`}</Typography>
            <Box style={{ width: 10 }} />
            {showAdjuster && (
                <>
                    <Typography className={classes.label} variant="h3">{`Adjust Salary: `}</Typography>
                    <Box style={{ flex: 4, paddingLeft: 50, paddingRight: 50 }}>
                        <AdjustSalarySlider
                            salary={salary}
                            ValueLabelComponent={ValueLabelComponent}
                            track={false}
                            valueLabelDisplay="on"
                            marks={[{ value: 0, label: "" }]}
                            min={-1 * range}
                            step={0.00001 * range}
                            max={range}
                            aria-labelledby="Salary slider"
                            InputProps={{
                                "aria-labelledby":"Salary Distribution",
                            }}
                            defaultValue={0}
                            onChangeCommitted={handleAdjustment}
                        />
                    </Box>
                </>
            )}
            <CustomTinyButton variant="contained" style={{ backgroundColor: theme.palette.primary.main }} onClick={handleEditIncome} >Edit income</CustomTinyButton>
        </Box>
    )
}

export default MiniInput