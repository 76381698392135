import React, { createContext, useContext, useReducer } from 'react'
import { Calculator } from '../core/Calculator';
import { InitialState } from '../core/calculatorConstants';
import { getCurrentYear, getTaxYearIndex } from '../core/calculatorUtils'
import { calculatePayments, date2str, defaultFrequencyValue } from '../utils/utils';
import { taxData } from '../core/calculatorData';

const LOCAL_STORAGE_NAME = "state_1.0";

export const DISPATCH_ADJUSTMENT = "adjustment";
export const DISPATCH_MAXIMISE_SUPER = "maximiseSuper";
export const DISPATCH_UPDATE_NOVATED_LEASE = "navatedLease";
export const DISPATCH_RESET_SALARY = "resetSalary";
export const DISPATCH_UPDATE = "update";
export const DISPATCH_UPDATE_YEAR = "updateYear";
export const DISPATCH_UPDATE_FULLTIME = "updateFulltime";
export const DISPATCH_UPDATE_OVERTIME = "updateOvertime";
export const DISPATCH_UPDATE_SALARY = "updateSalary";
export const DISPATCH_UPDATE_SALARY_PRIMARY = "updateSalaryPrimary";
export const DISPATCH_UPDATE_SALARY_SACRAFICE = "updateSalarySacrafice";
export const DISPATCH_MERGE_DATA = "mergeData";
export const INIT_CALCULATOR = "INIT_CALCULATOR";


let currentData = {};

export const reset = () => {
  saveData(InitialState);
}


export const setTaxData = (state) => {
  const yearIndex = getTaxYearIndex(state.year);
  currentTaxData = taxData[yearIndex]
}

export let currentTaxData = InitialState;


export const resetSuperRate = () => {
  const superannuationRate = Number(currentTaxData.superannuation.rate);
  return { superannuationRate };
}

export const setSuperRate = (year) => {
  const yearIndex = getTaxYearIndex(year);
  const superannuationRate = Number(taxData[yearIndex].superannuation.rate);
  return { superannuationRate };
}


export const checkPayDay = (state) => {
  const { year, payOption, warnings, casual, ytd, ytdFrom, ytdTo, fulltime, unpaidLeave, unpaidPublicHolidays } = state;

  // default
  let checkPayments = {};
  let extraPayment = false;

  checkPayments = calculatePayments(ytdFrom, ytdTo, year, payOption, ytd, casual, fulltime, unpaidLeave, unpaidPublicHolidays);
  extraPayment = checkPayments.payments.f > InitialState.payments.f || checkPayments.payments.w > InitialState.payments.w;

  return { payDay: date2str(ytdFrom), ...checkPayments, warnings: { ...warnings, extraPayment } };
}


export const readData = () => {
  let data = InitialState;

  if (typeof (Storage) !== "undefined") {
    try {
      const localData = localStorage.getItem(LOCAL_STORAGE_NAME);
      if (localData) {
        data = JSON.parse(localData);
      }
    }
    catch (error) { console.log(error) }
  }



  // Force old data to confirm to new
  // hours was 8 should be 7.6
  //frequency_day
  // if(data.casual.hoursPerWeek === undefined){
  //   data.casual.hours = InitialState.casual.hours
  // }
  if (data.casual.frequency_day === undefined) {
    data.casual.frequency_day = InitialState.casual.frequency_day
  }

  let timeDelta = new Date().getTime() - (data.timestamp ? new Date(data.timestamp) : new Date(0))
  //const REFRESH_THRESHOLD = 1000 * 60 * 60 * 6; // 6 hours
  const REFRESH_THRESHOLD = 1000 * 1; // 1 second ( force new financial year refresh )

  if (timeDelta > REFRESH_THRESHOLD) {
    data = { ...InitialState, ...data, ...getCurrentYear(data.year), adjustment: 0 };
  } else {
    data = { ...InitialState, ...data, adjustment: 0 };
  }
  data = { ...data, ...checkPayDay(data) };

  // check if the super rate has been adjusted, otherwise reset the defalut super rate
  if (!data.adjustSuperannuationRate) {
    data = { ...data, ...setSuperRate(data.year) };
  }

  Object.keys(data).map(key => {
    // Remove redundant objects
    if (InitialState[key] === undefined) {
      try {
        delete data[key];
      } catch (e) { console.log(e) }
    }

    // Reset if the data has become undefined (POTENTIALLY DANGEROUS)
    if (typeof InitialState[key] === "number" && data[key] === "") {
      console.log("RESET", key, InitialState[key])
      data[key] = InitialState[key];
    }
    return true;
  })

  // fill-in missing data objects
  Object.keys(data).map(key => {
    // Remove redundant objects
    if (typeof InitialState[key] === "object") {
      // check saved data
      Object.keys(data[key]).map(subkey => {
        if (InitialState[key][subkey] === undefined && key !== "overtime" && key !== "children") {
          if (data[key] && data[key][subkey]) {
            try {
              console.log("delete:", key, subkey)
              delete data[key][subkey];
            } catch (e) { console.log(e) }
          }
        }
        return true;
      });

      // check initial data
      Object.keys(InitialState[key]).map(subkey => {
        if (data[key][subkey] === undefined) {
          console.log("ADD", key, subkey, InitialState[key][subkey])
          data[key][subkey] = InitialState[key][subkey]
        }
        return true;
      })

    }
    return true;
  })

  // purge empty overtime
  if (data.overtime) {
    data.overtime = data.overtime.filter(ot => ot !== undefined && ot !== null)

    data.overtime.forEach((ot) => {
      if (!ot.annualFrequency) ot.annualFrequency = defaultFrequencyValue(ot.frequency);
    });

  }


  // purge empty children
  if (data.children) {
    let validChildren = []
    data.children.forEach((child) => {
      if (child !== undefined && child.age !== undefined) validChildren.push(child)
    })
    data.children = []
    validChildren.forEach((child) => data.children.push(child))
  }

  // qualify data
  if (data.capitalGains < 0) data.capitalGains = 0;

  setTaxData(data);

  //data = calculate(data); // wait for main app to trigger the calculate routine

  currentData = { ...data };
  return data;
}

export const saveData = (data) => {
  // only save the theme type not the entire object
  let saveData = { ...data, timestamp: new Date().getTime() }

  if (typeof (Storage) !== "undefined") {
    const newData = JSON.stringify(saveData);
    return localStorage.setItem(LOCAL_STORAGE_NAME, newData);

  }
  return;
}


const calculateState = (state) => {
  const result = Calculator.setState(state)
    .reset()
    .getIncome()
    .calculateProRata()
    .calculateOvertime()
    .calculateAllowances()
    .calculateFringeBenefits()
    .calculateSuperannuationGuarantee()
    .calculateNovatedLease()
    .calculateSalarySacraficeIncome()
    .calculateSalarySacrafice()
    .calculateSuperannuationCoContribution()
    .calculateTotalSuperannuation()
    .calculateSuperannuationLISTO()
    .calculateTotalSuperannuationTax()
    .calculateSpouseSuperannuationOffset()
    .calculateTaxableIncome()
    .calculateDivision293()
    .calculateIncomeTax()
    .calculateIncomeTaxExtraWitholding()
    .calculateStudentLoans()
    .calculateMedicare()
    .calculateMedicareAdjustment()
    .calculateFTB()
    .calculateOffsets()
    .calculateGrossTax()
    .calculateNetIncome()
  return result.state;
}


export const calculate = (state) => {
  // console.time("calculate")
  let result = calculateState(state)
  const savings = taxSavingsScenario(result)
  result = { ...result, ...savings }
  // console.timeEnd("calculate")
  return result;

}

const taxSavingsScenario = (state) => {
  let savings = {} // add properties where adjustments are made
  let scenario = JSON.parse(JSON.stringify(state)); // duplicate of state

  if (state.adjustSalaryScaracfice === true) {
    // run the calcualtions without the scarifice
    scenario.adjustSalaryScaracfice = false
    scenario.additionalSuperMaximise = false

    // keep the voluntry amount below concessional
    if (scenario.adjustSuperannuation) {
      if (state.superannationNonConcessional > 0) {
        scenario.additionalSuper = scenario.additionalSuper - state.superannationNonConcessional
      }
    }
    const scenarioResult = calculateState(scenario)

    // compare tax saving
    savings.superannuationSacraficeSavings = scenarioResult.grossTax.a - state.grossTax.a;
    savings.superannuationSacraficeNetSavings = savings.superannuationSacraficeSavings + (scenarioResult.superannuationTax.a - state.superannuationTax.a)

    // reset
    scenario.adjustSalaryScaracfice = true
    scenario.additionalSuper = state.additionalSuper
  }


  if (state.adjustSuperannuation === true) {
    // run the calcualtions without the scarifice
    scenario.adjustSuperannuation = false
    const scenarioResult = calculateState(scenario)

    // compare tax saving
    savings.superannuationConcessionalSavings = scenarioResult.grossTax.a - state.grossTax.a
    savings.superannuationConcessionalNetSavings = savings.superannuationConcessionalSavings + (scenarioResult.superannuationTax.a - state.superannuationTax.a)

    scenario.adjustSuperannuation = true
  }


  if (state.adjustDeductions) {
    scenario.adjustDeductions = false;
    const scenarioResult = calculateState(scenario)
    // compare tax saving
    savings.taxableDeductionsTax = scenarioResult.grossTax.a - state.grossTax.a;
    // reset
    scenario.adjustDeductions = true
  }

  if (state.capitalGains !== 0) {
    scenario.capitalGains = 0;
    const scenarioResult = calculateState(scenario)
    // compare tax saving
    savings.capitalGainsTax = scenarioResult.grossTax.a - state.grossTax.a;
    // reset
    scenario.capitalGains = state.capitalGains
  }


  if (state.adjustOtherIncome) {
    scenario.adjustOtherIncome = false;
    const scenarioResult = calculateState(scenario)
    // compare tax saving
    savings.otherIncomeTax = scenarioResult.grossTax.a - state.grossTax.a;
    // reset
    scenario.adjustOtherIncome = true
  }


  if (state.hasBonus) {
    scenario.hasBonus = false;
    const scenarioResult = calculateState(scenario)
    // compare tax saving
    savings.bonusIncomeTax = scenarioResult.grossTax.a - state.grossTax.a;
    // reset
    scenario.hasBonus = true
  }


  if (state.businessIncome) {
    scenario.businessIncome = false;
    const scenarioResult = calculateState(scenario)
    // compare tax saving
    savings.businessIncomeTax = scenarioResult.grossTax.a - state.grossTax.a;
    // reset
    scenario.businessIncome = true
  }

  if (state.businessIncomeLoss) {
    scenario.businessIncomeLoss = false;
    const scenarioResult = calculateState(scenario)
    // compare tax saving
    savings.businessIncomeLossTax = scenarioResult.grossTax.a - state.grossTax.a;
    // reset
    scenario.businessIncomeLoss = true
  }


  if (state.salarySacraficeIncomeAmount > 0) {
    scenario.salarySacraficeIncomeAmount = 0
    const scenarioResult = calculateState(scenario)
    savings.salarySacraficeIncomeSavings = scenarioResult.grossTax.a - state.grossTax.a;
  }

  if (state.novatedLeaseAmount > 0) {
    scenario.novatedLeaseAmount = 0
    const scenarioResult = calculateState(scenario)
    savings.novatedLeaseSavings = scenarioResult.grossTax.a - state.grossTax.a;
  }

  return savings;
}


// Instantiates the Context object (no initial data)
export const CalculatorContext = createContext();

// Calculator reducer ations
export const CalculatorReducer = (state, action) => {

  let newState = {};

  switch (action.type) {
    case INIT_CALCULATOR:

      newState = { ...state, ...currentData };
      return newState;


    case DISPATCH_MERGE_DATA:
      newState = { ...state, ...action.data };
      break;

    case DISPATCH_ADJUSTMENT:
      newState = {
        ...state,
        adjustment: Number(action.data),
      }
      break;

    case DISPATCH_RESET_SALARY:
      const preserveSalary = state.salary;
      const preservePayOption = state.payOption;
      newState = {
        ...state,
        ...InitialState,
        fulltime: true,
        ...getCurrentYear(),
        ...resetSuperRate(),
        salary: preserveSalary,
        payOption: preservePayOption,
      }

      break;

    case DISPATCH_UPDATE:
    case DISPATCH_UPDATE_NOVATED_LEASE:
      newState = {
        ...state,
        ...action,
        adjustment: 0,
      }

      break;


    case DISPATCH_MAXIMISE_SUPER:

      // maximise the super rate so that the contributions are zero
      let additionalRate = state.baseSalary.a > 0 ? state.superannationConcessionalRemaining / state.baseSalary.a : 0;
      let guaranteeRate = setSuperRate(state.year).superannuationRate;

      additionalRate *= 100;
      additionalRate += guaranteeRate;

      newState = {
        ...state,
        superannuationRate: additionalRate,
        adjustSuperannuationRate: true,
        superannuationGuaranteeCap: false,
      }

      break;

    case DISPATCH_UPDATE_YEAR:

      newState = {
        ...state,
        ...action,
        adjustment: 0,
      }
      // force new super rate if not set
      if (!newState.adjustSuperannuationRate) {
        newState = {
          ...newState,
          ...setSuperRate(newState.year),
        }
      }
      break;

    case DISPATCH_UPDATE_FULLTIME:

      newState = {
        ...state,
        ...action,
        adjustment: 0,
      }

      if (newState.fulltime) {
        // reset casual to fulltime
        newState = { ...newState, casual: InitialState.casual }
      }

      break;

    case DISPATCH_UPDATE_OVERTIME:
      newState = {
        ...state,
        overtime: [...action.overtime],
        adjustment: 0,
      }
      break;

    case DISPATCH_UPDATE_SALARY:
      // Called from the mini input
      const salary = Number(action.salary);
      newState = {
        ...state,
        salary: salary,
        adjustment: 0,
      }
      break;


    case DISPATCH_UPDATE_SALARY_PRIMARY:
      // this was done to try and fix a problem a few people were havinog with adjusting the salary input field
      // Maybe it was fixed by de coupling the adjust salary with the salary - who knows.
      const salaryAlt = Number(action.salary);
      newState = {
        ...state,
        salary: salaryAlt,
        adjustment: 0,
      }

      newState = calculate(newState);
      saveData(newState);
      return newState


    case DISPATCH_UPDATE_SALARY_SACRAFICE:
      // ensure that limits on ss amount is observed
      newState = {
        ...state,
        ...action,
        adjustment: 0,
      }
      break;

    default:
      // no change
      return state;
  }

  // check for leapiness only if the pay date changed!! (this takes 2 milli seconds)
  if (newState.payDay !== state.payDay
    || newState.year !== state.year
    || newState.payOption !== state.payOption
    || newState.casual.days !== state.casual.days
    || newState.casual.hours !== state.casual.hours
    || newState.casual.frequency !== state.casual.frequency
    || newState.ytd !== state.ytd
    || newState.ytdFrom !== state.ytdFrom
    || newState.ytdTo !== state.ytdTo
    || newState.unpaidLeave !== state.unpaidLeave
    || newState.unpaidPublicHolidays !== state.unpaidPublicHolidays
  ) {
    newState = {
      ...newState,
      ...checkPayDay(newState),
    }
  }

  setTaxData(newState);

  // perform calculation
  newState = calculate(newState);
  newState.count = newState.count + 1;
  saveData(newState);
  return newState
};



// Wrapper for the Provider injection
export const CalculatorProvider = ({ initialState, children }) => {

  return (
    <CalculatorContext.Provider value={useReducer(CalculatorReducer, initialState)}>
      {children}
    </CalculatorContext.Provider>
  )
};



// a custom hook function to access your state/reducer in any component
// It returns [state, dispatch] array, that is passed as a value to our Provider.
// const [{ superannuationRate, additionalSuper, deductions, nonResident, backpacker, noTaxFree, HELP, withhold }, dispatch] = useCalculator();

// NOTE: A component calling useContext will always re-render when the context value changes. If re-rendering the component is expensive, you can optimize it by using memoization.
export const useCalculator = () => useContext(CalculatorContext);