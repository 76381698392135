import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  alpha,
} from '@material-ui/core';


const useStyles = makeStyles(theme => {
  const bg = alpha(theme.palette.background.paper, 0.95);

  return {
    stickyRight: {
      position: 'sticky',
      top: 60,
      height: 600,
      // width:300,
      [theme.breakpoints.down('md')]: {
        top: 280,
        // width:160,
      },
      [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
        top: 80,
      },

      float: 'right',
      marginRight: 'auto',
      marginLeft: 'auto',
      zIndex: 10,
      backgroundColor: bg,

      '& > div': {
        minWidth: 300,
        [theme.breakpoints.only('sm')]: {
          minWidth: 160,
        },
      },
    },

    stickyBottom: {
      position: 'sticky',
      bottom: 10,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 320,
      height: 50,
      zIndex: 10,
      // backgroundColor: bg,
    },

    header: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      height: 250,
      [theme.breakpoints.down('sm')]: {
        height: 100,
      },
      overflow:"hidden",
      marginBottom: 10,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      backgroundColor: bg,

      '& > div': {
        minWidth: 320,
        [theme.breakpoints.only('sm')]: {
          minWidth: 728,
        },
        [theme.breakpoints.up('md')]: {
          minWidth: 970,
        },
      },
    },

    headerContent: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      height: 90,
      overflow:"hidden",
      [theme.breakpoints.down('sm')]: {
        height: 100,
      },
      marginBottom: 10,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      backgroundColor: bg,

      '& > div': {
        minWidth: 320,
        [theme.breakpoints.only('sm')]: {
          minWidth: 728,
        },
        [theme.breakpoints.up('md')]: {
          minWidth: 728,
        },
      },
    },

    content_1: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 10,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      backgroundColor: bg,
      overflow:"hidden",

      '& > div': {
        minWidth: 320,
        [theme.breakpoints.up('lg')]: {
          minWidth: 728,
        },
      }
    },

    content_2: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 10,
      marginBottom: 10,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      backgroundColor: bg,
      overflow:"hidden",

      '& > div': {
        minWidth: 320,
        [theme.breakpoints.up('lg')]: {
          minWidth: 728,
        },
      },
    }
  }
});


// New FUSE ad units
export const PC_HEADER_NATIVE = ({keyAttribute}) => {

    const [attr, setAttr] = useState(keyAttribute);
    // Update state when prop changes
    useEffect(() => {
      setAttr(keyAttribute);
    }, [keyAttribute]);
  
  // Native header ad
  const classes = useStyles();
  return <div className={classes.header} data-key-attr={attr} dangerouslySetInnerHTML={{ __html: '<div data-fuse="22032238564"></div>' }} />

}

export const PC_CONTENT_NATIVE = ({keyAttribute}) => {

  const [attr, setAttr] = useState(keyAttribute)
  // Update state when prop changes
  useEffect(() => {
    setAttr(keyAttribute);
  }, [keyAttribute]);

  // header ad unit (calculator page)
  const classes = useStyles();
  return <div className={classes.headerContent} data-key-attr={attr} dangerouslySetInnerHTML={{ __html: '<div data-fuse="22032356877"></div>' }} />
}



export const PC_CONTENT_1_TOP = ({keyAttribute}) => {

    const [attr, setAttr] = useState(keyAttribute);
    // Update state when prop changes
    useEffect(() => {
      setAttr(keyAttribute);
    }, [keyAttribute]);

  // multi content
  const classes = useStyles();
  // <!-- 71161633/PC_incontent_1/PC_incontent_1 -->
  return <div className={classes.content_1} data-key-attr={attr} dangerouslySetInnerHTML={{ __html: '<div data-fuse="22822435020"></div>' }} />
}


export const PC_CONTENT_1 = ({keyAttribute}) => {

    const [attr, setAttr] = useState(keyAttribute);
    // Update state when prop changes
    useEffect(() => {
      setAttr(keyAttribute);
    }, [keyAttribute]);


  // multi content
  const classes = useStyles();
  // <!-- 71161633/PC_incontent_1/PC_incontent_1 -->
  return <div className={classes.content_1} data-key-attr={attr} dangerouslySetInnerHTML={{ __html: '<div data-fuse="22056278571"></div>' }} />
}

export const PC_CONTENT_2 = ({keyAttribute}) => {

    const [attr, setAttr] = useState(keyAttribute);
    // Update state when prop changes
    useEffect(() => {
      setAttr(keyAttribute);
    }, [keyAttribute]);


  // multi content
  const classes = useStyles();
  // <!-- 71161633/PC_incontent_2/PC_incontent_2 -->
  return <div className={classes.content_2} data-key-attr={attr} dangerouslySetInnerHTML={{ __html: '<div data-fuse="22056278574"></div>' }} />
}


export const PC_RIGHT_STICKY = ({keyAttribute}) => {

    const [attr, setAttr] = useState(keyAttribute);
    // Update state when prop changes
    useEffect(() => {
      setAttr(keyAttribute);
    }, [keyAttribute]);

  // Mobile footer
  const classes = useStyles();
  return <div className={classes.stickyRight} data-key-attr={attr} dangerouslySetInnerHTML={{ __html: '<div data-fuse="22032238567"></div>' }} />
}
export const PC_RIGHT_STICKY_AUX = ({ adStyle, keyAttribute }) => {
    
  const [attr, setAttr] = useState(keyAttribute);
    // Update state when prop changes
    useEffect(() => {
      setAttr(keyAttribute);
    }, [keyAttribute]);


  // Mobile footer
  const style = { ...adStyle, marginTop: 115 };
  const classes = useStyles();
  return <div className={classes.stickyRight} style={style} data-key-attr={attr}  dangerouslySetInnerHTML={{ __html: '<div data-fuse="22032238567"></div>' }} />
}

export const PC_FOOTER_STICKY = ({keyAttribute}) => {

  const [attr, setAttr] = useState(keyAttribute);
  // Update state when prop changes
  useEffect(() => {
    setAttr(keyAttribute);
  }, [keyAttribute]);

  // Mobile footer

  const classes = useStyles();
  return <div className={classes.stickyBottom} data-key-attr={attr} dangerouslySetInnerHTML={{ __html: '<div data-fuse="22032356874"></div>' }} />
}


export const PC_OUTSTREAM_VIDEO= ({keyAttribute}) => {

  const [attr, setAttr] = useState(keyAttribute);
  // Update state when prop changes
  useEffect(() => {
    setAttr(keyAttribute);
  }, [keyAttribute]);

  // 71161633/bbw_outstream/bbw_outstream 
  return <div data-key-attr={attr} data-fuse="22424468456"></div>
}

