// ASCII
//https://patorjk.com/software/taag/#p=display&h=0&v=0&f=Fraktur&t=2023

//                                                                                   
//                                                                                   
//             .--~*teu.        .n~~%x.       .--~*teu.        .n~~%x.               
//            dF     988Nx    x88X   888.    dF     988Nx    x88X   888.             
//           d888b   `8888>  X888X   8888L  d888b   `8888>  X888X   8888L            
//           ?8888>  98888F X8888X   88888  ?8888>  98888F X8888X   88888            
//            "**"  x88888~ 88888X   88888X  "**"  x88888~ 88888X   88888X           
//                 d8888*`  88888X   88888X       d8888*`  88888X   88888X           
//               z8**"`   : 88888X   88888f     z8**"`   : 88888X   88888f           
//             :?.....  ..F 48888X   88888    :?.....  ..F 48888X   88888            
//            <""888888888~  ?888X   8888"   <""888888888~  ?888X   8888"            
//            8:  "888888*    "88X   88*`    8:  "888888*    "88X   88*`             
//            ""    "**"`       ^"==="`      ""    "**"`       ^"==="`               
//                                                                                   
//                                                                                   
//    

export const taxDistribution2020 = {

        year: 2020,
        total: 520046,// 500872 + 4749 + 14425,
        change: 509475, //487343 + 6826 + 15306,
        projection: 591029,// 559874 + 10780 + 20375,
        functions: [
            {
                label: "General public services", allocation: 23614, change: 26048, projection: 23802,
                subFunctions: [
                    { label: "Legislative and executive affairs", allocation: 1196, change: 1533, projection: 1199 },
                    { label: "Financial and fiscal affairs", allocation: 7344, change: 6922, projection: 7495 },
                    {
                        label: "Foreign affairs and economic aid", allocation: 6253, change: 5750, projection: 5918,
                        subFunctions: [
                            { label: "Foreign aid", allocation: 3947, change: 3517, projection: 3657 },
                            { label: "Diplomacy", allocation: 1050, change: 1019, projection: 959 },
                            { label: "Payments to international organisations", allocation: 503, change: 445, projection: 580 },
                            { label: "Passport services", allocation: 238, change: 242, projection: 246 },
                            { label: "International police assistance", allocation: 196, change: 204, projection: 143 },
                            { label: "Intl. agriculture research & dev", allocation: 121, change: 128, projection: 117 },
                            { label: "Consular services", allocation: 104, change: 97, projection: 104 },
                            { label: "Finance & insurance for exporters & investors", allocation: 7, change: 11, projection: 5 },
                            { label: "Other", allocation: 88, change: 87, projection: 107 },
                        ]
                    },
                    {
                        label: "General research", allocation: 3159, change: 3012, projection: 3476,
                        subFunctions: [
                            { label: "Research - science services & innovation fund", allocation: 1142, change: 1153, projection: 1214 },
                            { label: "Discovery - research and research training", allocation: 508, change: 495, projection: 538 },
                            { label: "Science & technology solutions", allocation: 392, change: 354, projection: 399 },
                            { label: "Linkage - cross sector research partnerships", allocation: 286, change: 272, projection: 303 },
                            { label: "Supporting science and commercialisation", allocation: 283, change: 234, projection: 282 },
                            { label: "Research capacity", allocation: 191, change: 168, projection: 401},
                            { label: "Other", allocation: 357, change: 337, projection: 338 },
                        ]
                    },
                    { label: "General services", allocation: 651, change: 665, projection: 581 },
                    { label: "Government superannuation benefits", allocation: 5011, change: 8166, projection: 5134 },
                ]
            },
            {
                label: "Defence", allocation: 32243, change: 31014, projection: 37629,
            },
            {
                label: "Public order and safety", allocation: 5919, change: 5760, projection: 5613,
                subFunctions: [
                    { label: "Courts and legal services", allocation: 1396, change: 1266, projection: 1316 },
                    { label: "Policing and law enforcement", allocation: 3060, change: 3094, projection: 2993 },
                    { label: "Border protection", allocation: 1463, change: 1399, projection: 1304 },
                ]
            },
            {
                label: "Education", allocation: 36350, change: 34773, projection: 40893,
                subFunctions: [
                    { label: "Higher education", allocation: 9856, change: 9704, projection: 10372 },
                    { label: "Vocational and other education", allocation: 1699, change: 1675, projection: 1620 },
                    { label: "Non-government schools", allocation: 12554, change: 19641, projection: 25365 },
                    { label: "Government schools", allocation: 8326, change: 7684, projection: 10385 },
                    { label: "School education - specific funding", allocation: 742, change: 691, projection: 151 },
                    { label: "Student assistance", allocation: 2753, change: 2675, projection: 3011 },
                    { label: "General administration", allocation: 420, change: 388, projection: 374 },
                ]
            },
            {
                label: "Health", allocation: 81777, change: 80569, projection: 89544,
                subFunctions: [
                    {
                        label: "Medical services and benefits", allocation: 33687, change: 32197, projection: 38814,
                        subFunctions: [
                            { label: "Medical benefits", allocation: 25526, change: 24228, projection: 30085 },
                            { label: "Private health insurance", allocation: 6574, change: 6393, projection: 7103 },
                            { label: "General medical consultations and services", allocation: 740, change: 764, projection: 730 },
                            { label: "Dental services", allocation: 346, change: 346, projection: 364 },
                            { label: "Other", allocation: 500, change: 468, projection: 532 },
                        ]
                    },
                    {
                        label: "Pharmaceutical benefits and services", allocation: 12688, change: 13457, projection: 11085,
                        subFunctions: [
                            { label: "Pharmaceutical benefits, services and supply ", allocation: 11971, change: 12731, projection: 10384 },
                            { label: "Immunisation", allocation: 395, change: 393, projection: 391 },
                            { label: "Veterans' pharmaceutical benefits", allocation: 322, change: 333, projection: 309 },
                        ]
                    },
                    { label: "Assistance to the States for public hospitals", allocation: 22535, change: 21708, projection: 26183 },
                    { label: "Hospital services", allocation: 1298, change: 1385, projection: 1149 },
                    { label: "Health services", allocation: 7371, change: 7505, projection: 8021 },
                    { label: "General administration", allocation: 3236, change: 3395, projection: 3228 },
                    { label: "Aboriginal and Torres Strait Islander health", allocation: 962, change: 922, projection: 1065 },
                ]
            },
            {
                label: "Social security and welfare", allocation: 180125, change: 172749, projection: 200217,
                subFunctions: [
                    {
                        label: "Assistance to the aged", allocation: 70151, change: 67449, projection: 80215,
                        subFunctions: [
                            { label: "Income Support for Seniors", allocation: 48301, change: 46741, projection: 54768 },
                            { label: "Aged Care Services", allocation: 20027, change: 18764, projection: 23903 },
                            { label: "Veterans' Community Care and Support", allocation: 1130, change: 1202, projection: 1025 },
                            { label: "Access and information", allocation: 245, change: 234, projection: 228 },
                            { label: "Mature Age Income Support", allocation: 131, change: 171, projection: 0 },
                            { label: "Aged Care Quality", allocation: 221, change: 242, projection: 210 },
                            { label: "Allowances, concessions & services for seniors", allocation: 83, change: 92, projection: 61 },
                            { label: "National Partnership Payments - Assistance to the Aged", allocation: 13, change: 3, projection: 21 },
                        ]
                    },
                    { label: "Assistance to veterans and dependants", allocation: 6707, change: 6717, projection: 6309 },
                    { label: "Assistance to people with disabilities", allocation: 47005, change: 44079, projection: 55499,
                        subFunctions:
                            [
                                { label: "Income Support for People with Disability", allocation: 17057, change: 16699, projection: 17889 },
                                { label: "National Disability Insurance Scheme", allocation: 17524, change: 12989, projection: 24806 },
                                { label: "Income Support for Carers", allocation: 9207, change: 8770, projection: 10552 },
                                { label: "Assistance to the States for Disability Services", allocation: 173, change: 958, projection: 0 },
                                { label: "Disability and Carers", allocation: 1084, change: 1012, projection: 1112 },
                                { label: "National Disability Insurance Scheme Transition Programme", allocation: 219, change: 500, projection: 37 },
                                { label: "National Partnership Payments - Assistance to People with Disabilities", allocation: 1742, change: 3151, projection: 1104 },
                            ]
                    },

                    { label: "Assistance to families with children", allocation: 37412, change: 35754, projection: 39471,
                        subFunctions: [
                            { label: "Family tax benefit", allocation: 18013, change: 17630, projection: 1833 },
                            { label: "Child Care Subsidy", allocation: 8267, change: 7725, projection: 9646 },
                            { label: "Parents income support", allocation: 5190, change: 5171, projection: 5487 },
                            { label: "Paid Parental Leave", allocation: 2326, change: 2250, projection: 2551 },
                            { label: "Child support", allocation: 2002, change: 1983, projection: 2118 },
                            { label: "Support for the child care system", allocation: 342, change: 329, projection: 350 },
                            { label: "Families and Children", allocation: 979, change: 361, projection: 679 },
                            { label: "Family relationship services", allocation: 182, change: 168, projection: 188 },
                            { label: "Child Payments", allocation: 96, change: 103, projection: 103 },
                            { label: "Other", allocation: 14, change: 15, projection: 15 },
                        ]
                    },
                    { label: "Assistance to the unemployed and the sick", allocation: 10834, change: 0, projection: 0 },
                    { label: "Other welfare programs", allocation: 1729, change: 0, projection: 0 },
                    { label: "Assistance for Indigenous Australians nec", allocation: 2269, change: 0, projection: 0 },
                    { label: "General administration", allocation: 4016, change: 0, projection: 0 },
                ]
            },
            {
                label: "Housing and community amenities", allocation: 5907, change: 5278, projection: 4767,
                subFunctions: [
                    { label: "Housing", allocation: 3141, change: 3051, projection: 3096 },
                    { label: "Urban and regional development", allocation: 1773, change: 1288, projection: 570 },
                    { label: "Environment protection", allocation: 992, change: 939, projection: 1101 },
                ]
            },
            {
                label: "Recreation and culture", allocation: 3849, change: 3988, projection: 3696,
                subFunctions: [
                    {
                        label: "Broadcasting", allocation: 1476, change: 1490, projection: 1500,
                        subFunction: [
                            { label: "ABC general operational activities", allocation: 900, change: 916, projection: 902 },
                            { label: "SBS general operational activities", allocation: 319, change: 319, projection: 326 },
                            { label: "ABC transmission and distribution services", allocation: 184, change: 181, projection: 194 },
                            { label: "SBS transmission and distribution services", allocation: 73, change: 73, projection: 77 },
                        ]
                    },
                    { label: "Arts and cultural heritage", allocation: 1437, change: 1455, projection: 1380 },
                    { label: "Sport and recreation", allocation: 489, change: 584, projection: 329 },
                    { label: "National estate and parks", allocation: 448, change: 459, projection: 487 },
                ]
            },
            {
                label: "Fuel and energy", allocation: 8171, change: 7956, projection: 9285,
                subFunctions: [
                    { label: "Fuel Tax Credits Scheme", allocation: 7504, change: 7168, projection: 8966 },
                    { label: "Resources and Energy", allocation: 126, change: 141, projection: 70 },
                    { label: "Renewable Energy", allocation: 352, change: 405, projection: 102 },
                    { label: "Other", allocation: 189, change: 242, projection: 147 },
                ]
            },
            {
                label: "Agriculture, forestry and fishing", allocation: 2871, change: 3149, projection: 3014,
                subFunctions: [
                    { label: "Wool industry", allocation: 70, change: 87, projection: 77 },
                    { label: "Grains industry", allocation: 202, change: 187, projection: 210 },
                    { label: "Dairy industry", allocation: 48, change: 49, projection: 49 },
                    { label: "Cattle, sheep and pig industry", allocation: 242, change: 244, projection: 258 },
                    { label: "Fishing, horticulture and other agriculture", allocation: 381, change: 404, projection: 350 },
                    { label: "General assistance not allocated to specific industries", allocation: 34, change: 55, projection: 31 },
                    { label: "Rural assistance", allocation: 299, change: 336, projection: 249 },
                    {
                        label: "Natural resources development", allocation: 776, change: 973, projection: 955,
                        subFunctions: [
                            { label: "Water reform", allocation: 525, change: 699, projection: 750 },
                            { label: "Sustainable management - natural resources", allocation: 9, change: 10, projection: 16 },
                            { label: "Other", allocation: 243, change: 263, projection: 189 },
                        ]
                    },
                    { label: "General administration", allocation: 823, change: 815, projection: 835 },
                ]
            },
            {
                label: "Mining, manufacturing and construction", allocation: 3422, change: 2592, projection: 2926, subFunctions:
                    [
                        { label: "Research and Development Tax Incentive", allocation: 2237, change: 1967, projection: 2410 },
                        { label: "Growing Business Investment", allocation: 279, change: 317, projection: 172 },
                        { label: "Northern Australia Infrastructure Facility", allocation: 639, change: 45, projection: 116 },
                        { label: "Other", allocation: 267, change: 262, projection: 227 },
                    ]
            },
            {
                label: "Transport and communication", allocation: 9038, change: 8105, projection: 11149,
                subFunctions: [
                    { label: "Communication", allocation: 1466, change: 964, projection: 1341 },
                    { label: "Rail transport", allocation: 1001, change: 1134, projection: 1818 },
                    { label: "Air transport", allocation: 350, change: 355, projection: 277 },
                    { label: "Road transport", allocation: 5587, change: 5016, projection: 7033 },
                    { label: "Sea transport", allocation: 444, change: 438, projection: 475 },
                    { label: "Other transport and communication", allocation: 190, change: 198, projection: 205 }
                ]
            },
            {
                label: "Other economic affairs", allocation: 9297, change: 10281, projection: 8996,
                subFunctions: [
                    { label: "Tourism and area promotion", allocation: 169, change: 163, projection: 185 },
                    { label: "Vocational and industry training", allocation: 1173, change: 1172, projection: 1134 },
                    { label: "Labour market assistance to job seekers & industry", allocation: 1831, change: 2005, projection: 1818 },
                    { label: "Industrial relations", allocation: 703, change: 687, projection: 715 },
                    {
                        label: "Immigration", allocation: 2854, change: 3761, projection: 2542,
                        subFunction: [
                            { label: "Management of unlawful non-citizens", allocation: 1624, change: 2458, projection: 1444 },
                            { label: "Citizenship, visas & migration", allocation: 782, change: 841, projection: 695 },
                            { label: "Regional co-operation, refugee & humanitarian assistance", allocation: 389, change: 463, projection: 402 },
                        ]
                    },
                    {
                        label: "Other economic affairs", allocation: 2567, change: 2492, projection: 2394,
                        subFunction: [
                            { label: "Promotion of Australia's export & other international economic interests", allocation: 389, change: 360, projection: 360 },
                            { label: "Department of Industry, Innovation and Science", allocation: 487, change: 507, projection: 465 },
                            { label: "Australian Securities & Investments Commission", allocation: 512, change: 477, projection: 537 },
                            { label: "Bureau of Meteorology", allocation: 392, change: 381, projection: 348 },
                            { label: "IP Australia ", allocation: 206, change: 201, projection: 226 },
                            { label: "Australian Competition & Consumer Commission", allocation: 168, change: 166, projection: 135 },
                            { label: "Australian Prudential Regulation Authority", allocation: 184, change: 156, projection: 201 },
                            { label: "National Partnership Payments - Competition & Productivity Enhancing Reform", allocation: 107, change: 129, projection: 0 },
                            { label: "Other", allocation: 122, change: 115, projection: 120 },
                        ]
                    },
                ]
            },
            {
                label: "Other purposes", allocation: 98287, change: 95081, projection: 118343,
                subFunctions: [
                    { label: "Public debt interest", allocation: 17037, change: 17154, projection: 15747 },
                    { label: "Nominal superannuation interest", allocation: 11127, change: 9447, projection: 12122 },
                    { label: "General revenue assistance - States & Territories", allocation: 69053, change: 67134, projection: 78608 },
                    { label: "Local government assistance", allocation: 2564, change: 1275, projection: 2883 },
                    { label: "Natural disaster relief", allocation: 11, change: 775, projection: 0 },
                    { label: "Contingency reserve", allocation: -216, change: -1993, projection: 8982 },
                ]
            },



            {
                label: "Capital Investment", allocation: 4749, change: 6490, projection: 10780,
                subFunctions: [
                    { label: "General public services", allocation: -1, change: 113, projection: -189 },
                    { label: "Defence", allocation: 5202, change: 4727, projection: 11294 },
                    { label: "Public order and safety", allocation: 91, change: 190, projection: -293 },
                    { label: "Education", allocation: 16, change: 4, projection: -3 },
                    { label: "Health", allocation: 58, change: 62, projection: -27 },
                    { label: "Social security and welfare", allocation: -38, change: 114, projection: -104 },
                    { label: "Housing and community amenities", allocation: -12, change: 91, projection: -3 },
                    { label: "Recreation and culture", allocation: 138, change: 213, projection: 50 },
                    { label: "Fuel and energy", allocation: 1, change: 1, projection: -1 },
                    { label: "Agriculture, forestry and fishing", allocation: 161, change: 904, projection: 143 },
                    { label: "Mining, manufacturing and construction", allocation: 15, change: 14, projection: -3 },
                    { label: "Transport and communication", allocation: -849, change: 35, projection: -15 },
                    { label: "Other economic affairs", allocation: -41, change: 25, projection: -192 },
                    { label: "Other purposes", allocation: 9, change: -3, projection: 68 },
                ]
            },


            {
                label: "Purchase of Assets", allocation: 14425, change: 15306, projection: 20375,
                subFunctions: [
                    { label: "General public services", allocation: 977, change: 1098, projection: 690 },
                    { label: "Defence", allocation: 10863, change: 10536, projection: 17914 },
                    { label: "Public order and safety", allocation: 594, change: 647, projection: 322 },
                    { label: "Education", allocation: 31, change: 43, projection: 26 },
                    { label: "Health", allocation: 134, change: 140, projection: 63 },
                    { label: "Social security and welfare", allocation: 373, change: 558, projection: 225 },
                    { label: "Housing and community amenities", allocation: 123, change: 104, projection: 95 },
                    { label: "Recreation and culture", allocation: 478, change: 549, projection: 403 },
                    { label: "Fuel and energy", allocation: 5, change: 6, projection: 5 },
                    { label: "Agriculture, forestry and fishing", allocation: 208, change: 952, projection: 190 },
                    { label: "Mining, manufacturing and construction", allocation: 24, change: 22, projection: 8 },
                    { label: "Transport and communication", allocation: 71, change: 97, projection: 59 },
                    { label: "Other economic affairs", allocation: 507, change: 580, projection: 306 },
                    { label: "Other purposes", allocation: 24, change: -3, projection: 68 },
                ]
            },

        ]
    };
    


    //                                                                                   
//                                                                                   
//               .--~*teu.        .n~~%x.       .--~*teu.          oe                
//              dF     988Nx    x88X   888.    dF     988Nx      .@88                
//             d888b   `8888>  X888X   8888L  d888b   `8888> ==*88888                
//             ?8888>  98888F X8888X   88888  ?8888>  98888F    88888                
//              "**"  x88888~ 88888X   88888X  "**"  x88888~    88888                
//                   d8888*`  88888X   88888X       d8888*`     88888                
//                 z8**"`   : 88888X   88888f     z8**"`   :    88888                
//               :?.....  ..F 48888X   88888    :?.....  ..F    88888                
//              <""888888888~  ?888X   8888"   <""888888888~    88888                
//              8:  "888888*    "88X   88*`    8:  "888888*     88888                
//              ""    "**"`       ^"==="`      ""    "**"`   '**%%%%%%**             
//                                                                                   
//                                                                                   
//  

    export const taxDistribution2021 = {
        year: 2021,
        total: 670330,
        change: 578549, 
        projection: 567491,
        functions: [
            {
                label: "General public services", allocation: 31764, change: 29472, projection: 24412,
                subFunctions: [
                    { label: "Legislative and executive affairs", allocation: 1401, change: 1366, projection: 1659 },
                    { label: "Financial and fiscal affairs", allocation: 7901, change: 7302, projection: 7715 },
                    {
                        label: "Foreign affairs and economic aid", allocation: 6541, change: 6270, projection: 5868,
                        subFunctions: [
                            { label: "Foreign aid", allocation: 4014, change: 3987, projection: 3515 },
                            { label: "Diplomacy", allocation: 1217, change: 1151, projection: 1061 },
                            { label: "Payments to international organisations", allocation: 476, change: 384, projection: 486 },
                            { label: "Passport services", allocation: 264, change: 224, projection: 267 },
                            { label: "International police assistance", allocation: 202, change: 201, projection: 209 },
                            { label: "Intl. agriculture research & dev", allocation: 112, change: 109, projection: 102 },
                            { label: "Consular services", allocation: 104, change: 97, projection: 104 },
                            { label: "Finance & insurance for exporters & investors", allocation: 7, change: 11, projection: 5 },
                            { label: "Other", allocation: 88, change: 87, projection: 107 },
                        ]
                    },
                    {
                        label: "General research", allocation: 3452, change: 2940, projection: 3410,
                        subFunctions: [
                            { label: "Research - science services & innovation fund", allocation: 1209, change: 1126, projection: 1164 },
                            { label: "Discovery - research and research training", allocation: 484, change: 487, projection: 488 },
                            { label: "Science & technology solutions", allocation: 435, change: 328, projection: 460 },
                            { label: "Linkage - cross sector research partnerships", allocation: 327, change: 295, projection: 326 },
                            { label: "Supporting science and commercialisation", allocation: 367, change: 194, projection: 332 },
                            { label: "Research capacity", allocation: 285, change: 187, projection: 303},
                            { label: "Other", allocation: 345, change: 323, projection: 336 },
                        ]
                    },
                    { label: "General services", allocation: 1190, change: 855, projection: 705 },
                    { label: "Government superannuation benefits", allocation: 11279, change: 10739, projection: 5055 },
                ]
            },
            {
                label: "Defence", allocation: 34415, change: 33187, projection: 35382,
            },
            {
                label: "Public order and safety", allocation: 6212, change: 6388, projection: 5802,
                subFunctions: [
                    { label: "Courts and legal services", allocation: 1488, change: 1416, projection: 1381 },
                    { label: "Policing and law enforcement", allocation: 3267, change: 3438, projection: 3117 },
                    { label: "Border protection", allocation: 1457, change: 1535, projection: 1304 },
                ]
            },
            {
                label: "Education", allocation: 41742, change: 39885, projection: 41512,
                subFunctions: [
                    { label: "Higher education", allocation: 11373, change: 9652, projection: 10570 },
                    { label: "Vocational and other education", allocation: 2229, change: 1713, projection: 1740 },
                    { label: "Non-government schools", allocation: 12844, change: 13918, projection: 14657 },
                    { label: "Government schools", allocation: 9067, change: 8387, projection: 9748 },
                    { label: "School education - specific funding", allocation: 723, change: 722, projection: 590 },
                    { label: "Student assistance", allocation: 5205, change: 5271, projection: 3920 },
                    { label: "General administration", allocation: 301, change: 222, projection: 286 },
                ]
            },
            {
                label: "Health", allocation: 87023, change: 93771, projection: 90313,
                subFunctions: [
                    {
                        label: "Medical services and benefits", allocation: 36530, change: 32668, projection: 36409,
                        subFunctions: [
                            { label: "Medical benefits", allocation: 28248, change: 24881, projection: 28146 },
                            { label: "Private health insurance", allocation: 6624, change: 6309, projection: 6667 },
                            { label: "General medical consultations and services", allocation: 772, change: 699, projection: 732 },
                            { label: "Dental services", allocation: 341, change: 284, projection: 345 },
                            { label: "Other", allocation: 546, change: 496, projection: 519 },
                        ]
                    },
                    {
                        label: "Pharmaceutical benefits and services", allocation: 14487, change: 14175, projection: 14445,
                        subFunctions: [
                            { label: "Pharmaceutical benefits, services and supply ", allocation: 13716, change: 13432, projection: 13648 },
                            { label: "Immunisation", allocation: 472, change: 440, projection: 479 },
                            { label: "Veterans' pharmaceutical benefits", allocation: 299, change: 303, projection: 319 },
                        ]
                    },
                    { label: "Assistance to the States for public hospitals", allocation: 23607, change: 22560, projection: 25192 },
                    { label: "Hospital services", allocation: 1169, change: 1248, projection: 1163 },
                    { label: "Health services", allocation: 13096, change: 11888, projection: 8806 },
                    { label: "General administration", allocation: 3906, change: 3510, projection: 3332 },
                    { label: "Aboriginal and Torres Strait Islander health", allocation: 975, change: 973, projection: 967 },
                ]
            },
            {
                label: "Social security and welfare", allocation: 227529, change: 196119, projection: 201730,
                subFunctions: [
                    {
                        label: "Assistance to the aged", allocation: 77986, change: 71855, projection: 75987,
                        subFunctions: [
                            { label: "Income Support for Seniors", allocation: 53549, change: 50104, projection: 51425 },
                            { label: "Aged Care Services", allocation: 21849, change: 19757, projection: 23032 },
                            { label: "Veterans' Community Care and Support", allocation: 950, change: 991, projection: 903 },
                            { label: "Access and information", allocation: 273, change: 230, projection: 296 },
                            { label: "Mature Age Income Support", allocation: 87, change: 152, projection: 9 },
                            { label: "Aged Care Quality", allocation: 688, change: 236, projection: 231 },
                            { label: "Allowances, concessions & services for seniors", allocation: 570, change: 379, projection: 79 },
                            { label: "National Partnership Payments - Assistance to the Aged", allocation: 20, change: 7, projection: 13 },
                        ]
                    },
                    { label: "Assistance to veterans and dependants", allocation: 8047, change: 7711, projection: 6812 },
                    { label: "Assistance to people with disabilities", allocation: 56157, change: 49038, projection: 55715,
                        subFunctions:
                            [
                                { label: "Income Support for People with Disability", allocation: 18506, change: 17781, projection: 17328 },
                                { label: "National Disability Insurance Scheme", allocation: 23431, change: 18676, projection: 25448 },
                                { label: "Income Support for Carers", allocation: 10102, change: 9375, projection: 9777 },
                                { label: "Assistance to the States for Disability Services", allocation: 0, change: 174, projection: 0 },
                                { label: "Disability and Carers", allocation: 1771, change: 1449, projection: 1811 },
                                // { label: "National Disability Insurance Scheme Transition Programme", allocation: 219, change: 500, projection: 37 },
                                { label: "National Partnership Payments - Assistance to People with Disabilities", allocation: 2347, change: 1582, projection: 1350 },
                            ]
                    },

                    { label: "Assistance to families with children", allocation: 42221, change: 38604, projection: 38345,
                        subFunctions: [
                            { label: "Family tax benefit", allocation: 19405, change: 18333, projection: 18208 },
                            { label: "Child Care Subsidy", allocation: 8978, change: 7921, projection: 9331 },
                            { label: "Parents income support", allocation: 7203, change: 6442, projection: 4995 },
                            { label: "Paid Parental Leave", allocation: 2288, change: 2399, projection: 2283 },
                            { label: "Child support", allocation: 2095, change: 2330, projection: 2124 },
                            { label: "Support for the child care system", allocation: 1341, change: 324, projection: 409 },
                            { label: "Families and Children", allocation: 625, change: 551, projection: 672 },
                            { label: "Family relationship services", allocation: 182, change: 183, projection: 212 },
                            { label: "Child Payments", allocation: 96, change: 106, projection: 96 },
                            { label: "Other", allocation: 17, change: 14, projection: 16 },
                        ]
                    },
                    { label: "Assistance to the unemployed and the sick", allocation: 34095, change: 20128, projection: 17302 },
                    { label: "Other welfare programs", allocation: 2044, change: 1869, projection: 1364 },
                    { label: "Assistance for Indigenous Australians nec", allocation: 2415, change: 2388, projection: 2377 },
                    { label: "General administration", allocation: 4563, change: 4526, projection: 3829 },
                ]
            },
            {
                label: "Housing and community amenities", allocation: 7086, change: 5332, projection: 5713,
                subFunctions: [
                    { label: "Housing", allocation: 3709, change: 2752, projection: 2867 },
                    { label: "Urban and regional development", allocation: 1954, change: 1292, projection: 1267 },
                    { label: "Environment protection", allocation: 1423, change: 1288, projection: 1579 },
                ]
            },
            {
                label: "Recreation and culture", allocation: 4364, change: 3971, projection: 4000,
                subFunctions: [
                    {
                        label: "Broadcasting", allocation: 1497, change: 1500, projection: 1511,
                        subFunction: [
                            { label: "ABC general operational activities", allocation: 898, change: 939, projection: 905 },
                            { label: "SBS general operational activities", allocation: 336, change: 331, projection: 340 },
                            { label: "ABC transmission and distribution services", allocation: 190, change: 160, projection: 192 },
                            { label: "SBS transmission and distribution services", allocation: 72, change: 70, projection: 74 },
                        ]
                    },
                    { label: "Arts and cultural heritage", allocation: 1647, change: 1439, projection: 1488 },
                    { label: "Sport and recreation", allocation: 601, change: 544, projection: 454 },
                    { label: "National estate and parks", allocation: 618, change: 487, projection: 547 },
                ]
            },
            {
                label: "Fuel and energy", allocation: 8771, change: 7892, projection: 8603,
                subFunctions: [
                    { label: "Fuel Tax Credits Scheme", allocation: 7838, change: 7343, projection: 7925 },
                    { label: "Resources and Energy", allocation: 105, change: 85, projection: 60 },
                    { label: "Renewable Energy", allocation: 433, change: 256, projection: 336 },
                    { label: "Other", allocation: 395, change: 208, projection: 282 },
                ]
            },
            {
                label: "Agriculture, forestry and fishing", allocation: 3913, change: 2584, projection: 3880,
                subFunctions: [
                    { label: "Wool industry", allocation: 48, change: 58, projection: 48 },
                    { label: "Grains industry", allocation: 226, change: 199, projection: 241 },
                    { label: "Dairy industry", allocation: 53, change: 55, projection: 53 },
                    { label: "Cattle, sheep and pig industry", allocation: 235, change: 230, projection: 242 },
                    { label: "Fishing, horticulture and other agriculture", allocation: 496, change: 393, projection: 315 },
                    { label: "General assistance not allocated to specific industries", allocation: 38, change: 39, projection: 36 },
                    { label: "Rural assistance", allocation: 1185, change: 426, projection: 497 },
                    {
                        label: "Natural resources development", allocation: 845, change: 431, projection: 1603,
                        subFunctions: [
                            { label: "Water reform", allocation: 559, change: 198, projection: 1287 },
                            { label: "Sustainable management - natural resources", allocation: 15, change: 7, projection: 15 },
                            { label: "Other", allocation: 271, change: 227, projection: 301 },
                        ]
                    },
                    { label: "General administration", allocation: 799, change: 754, projection: 845 },
                ]
            },
            {
                label: "Mining, manufacturing and construction", allocation: 3306, change: 2819, projection: 3696, subFunctions:
                    [
                        { label: "Research and Development Tax Incentive", allocation: 2464, change: 2232, projection: 2457 },
                        { label: "Growing Business Investment", allocation: 400, change: 260, projection: 677 },
                        { label: "Northern Australia Infrastructure Facility", allocation: 171, change: 91, projection: 303 },
                        { label: "Other", allocation: 272, change: 235, projection: 259},
                    ]
            },
            {
                label: "Transport and communication", allocation: 13060, change: 7321, projection: 14453,
                subFunctions: [
                    { label: "Communication", allocation: 1255, change: 679, projection: 1518 },
                    { label: "Rail transport", allocation: 1867, change: 555, projection: 2614 },
                    { label: "Air transport", allocation: 1836, change: 935, projection: 300 },
                    { label: "Road transport", allocation: 7379, change: 4499, projection: 9337 },
                    { label: "Sea transport", allocation: 455, change: 438, projection: 444 },
                    { label: "Other transport and communication", allocation: 268, change: 216, projection: 239 }
                ]
            },
            {
                label: "Other economic affairs", allocation: 97948, change: 65494, projection: 14281,
                subFunctions: [
                    { label: "Tourism and area promotion", allocation: 261, change: 165, projection: 179 },
                    { label: "Vocational and industry training", allocation: 7671, change: 3810, projection: 5801 },
                    { label: "Labour market assistance to job seekers & industry", allocation: 4011, change: 1083, projection: 2120 },
                    { label: "Industrial relations", allocation: 1133, change: 702, projection: 1093 },
                    {
                        label: "Immigration", allocation: 3836, change: 3488, projection: 2719,
                        subFunction: [
                            { label: "Management of unlawful non-citizens", allocation: 2244, change: 1975, projection: 1285 },
                            { label: "Citizenship, visas & migration", allocation: 822, change: 796, projection: 742 },
                            { label: "Regional co-operation, refugee & humanitarian assistance", allocation: 770, change: 718, projection: 691 },
                        ]
                    },
                    {
                        label: "Other economic affairs", allocation: 86180, change: 58030, projection: 5582,
                        subFunction: [
                            { label: "Economic Response to the Coronavirus", allocation: 82477, change: 55179, projection: 2900 },
                            { label: "Promotion of Australia's export & other international economic interests", allocation: 1138, change: 501, projection: 426 },
                            { label: "Department of Industry, Innovation and Science", allocation: 530, change: 523, projection: 494 },
                            { label: "Australian Securities & Investments Commission", allocation: 596, change: 682, projection: 579 },
                            { label: "Bureau of Meteorology", allocation: 441, change: 401, projection: 448 },
                            { label: "IP Australia ", allocation: 201, change: 204, projection: 209 },
                            { label: "Australian Competition & Consumer Commission", allocation: 208, change: 224, projection: 188 },
                            { label: "Australian Prudential Regulation Authority", allocation: 205, change: 194, projection: 216 },
                            { label: "National Partnership Payments - Competition & Productivity Enhancing Reform", allocation: 261, change: 0, projection: 0 },
                            { label: "Other", allocation: 122, change: 124, projection: 122 },
                        ]
                    },
                ]
            },
            {
                label: "Other purposes", allocation: 96449, change: 91062, projection: 113711,
                subFunctions: [
                    { label: "Public debt interest", allocation: 16804, change: 16923, projection: 17365 },
                    { label: "Nominal superannuation interest", allocation: 7004, change: 7672, projection: 11731 },
                    { label: "General revenue assistance - States & Territories", allocation: 64092, change: 64603, projection: 71213 },
                    { label: "Local government assistance", allocation: 2166, change: 2576, projection: 3179 },
                    { label: "Natural disaster relief", allocation: 482, change: 1863, projection: 832 },
                    { label: "Contingency reserve", allocation: 8068, change: 0, projection: 12570 },
                ]
            },



            {
                label: "Capital Investment", allocation: 7818, change: 4005, projection: 9906,
                subFunctions: [
                    { label: "General public services", allocation: 173, change: -487, projection: 134 },
                    { label: "Defence", allocation: 6616, change: 5124, projection: 8819 },
                    { label: "Public order and safety", allocation: 178, change: -235, projection: 569 },
                    { label: "Education", allocation: 20, change: 2, projection: 18 },
                    { label: "Health", allocation: 1136, change: 875, projection: -19 },
                    { label: "Social security and welfare", allocation: -249, change: -137, projection: -207 },
                    { label: "Housing and community amenities", allocation: -183, change: -362, projection: -172 },
                    { label: "Recreation and culture", allocation: 165, change: 158, projection: 92 },
                    { label: "Fuel and energy", allocation: 14, change: 80, projection: 2 },
                    { label: "Agriculture, forestry and fishing", allocation: 164, change: 85, projection: 259 },
                    { label: "Mining, manufacturing and construction", allocation: -16, change: -26, projection: -27 },
                    { label: "Transport and communication", allocation: -37, change: -883, projection: -23 },
                    { label: "Other economic affairs", allocation: 171, change: -188, projection: 598 },
                    { label: "Other purposes", allocation: -334, change: 0, projection: -136 },
                ]
            },


            {
                label: "Purchase of Assets", allocation: 18904, change: 15876, projection: 22597,
                subFunctions: [
                    { label: "General public services", allocation: 1620, change: 1083, projection: 1599 },
                    { label: "Defence", allocation: 13042, change: 11144, projection: 15802 },
                    { label: "Public order and safety", allocation: 1062, change: 701, projection: 1466 },
                    { label: "Education", allocation: 77, change: 42, projection: 77 },
                    { label: "Health", allocation: 294, change: 297, projection: 209 },
                    { label: "Social security and welfare", allocation: 516, change: 723, projection: 552 },
                    { label: "Housing and community amenities", allocation: 229, change: 258, projection: 291 },
                    { label: "Recreation and culture", allocation: 619, change: 638, projection: 576 },
                    { label: "Fuel and energy", allocation: 15, change: 4, projection: 14 },
                    { label: "Agriculture, forestry and fishing", allocation: 257, change: 181, projection: 354 },
                    { label: "Mining, manufacturing and construction", allocation: 22, change: 9, projection: 12 },
                    { label: "Transport and communication", allocation: 93, change: 84, projection: 107 },
                    { label: "Other economic affairs", allocation: 1041, change: 713, projection: 1481 },
                    { label: "Other purposes", allocation: 16, change: 0, projection: 58 },
                ]
            },

        ]
    };
    

  //                                                                                   
//                                                                                   
//              .--~*teu.        .n~~%x.       .--~*teu.      .--~*teu.              
//             dF     988Nx    x88X   888.    dF     988Nx   dF     988Nx            
//            d888b   `8888>  X888X   8888L  d888b   `8888> d888b   `8888>           
//            ?8888>  98888F X8888X   88888  ?8888>  98888F ?8888>  98888F           
//             "**"  x88888~ 88888X   88888X  "**"  x88888~  "**"  x88888~           
//                  d8888*`  88888X   88888X       d8888*`        d8888*`            
//                z8**"`   : 88888X   88888f     z8**"`   :     z8**"`   :           
//              :?.....  ..F 48888X   88888    :?.....  ..F   :?.....  ..F           
//             <""888888888~  ?888X   8888"   <""888888888~  <""888888888~           
//             8:  "888888*    "88X   88*`    8:  "888888*   8:  "888888*            
//             ""    "**"`       ^"==="`      ""    "**"`    ""    "**"`             
//                                                                                   
//    

    export const taxDistribution = {
        year: 2022,
        total: 589334,
        change: 659437, 
        projection: 595378,
        functions: [
            {
                label: "General public services", allocation: 26070, change: 33037, projection: 24811,
                subFunctions: [
                    { label: "Legislative and executive affairs", allocation: 1744, change: 1446, projection: 1385 },
                    { label: "Financial and fiscal affairs", allocation: 8067, change: 9013, projection: 7216 },
                    {
                        label: "Foreign affairs and economic aid", allocation: 6340, change: 6949, projection: 6204,
                        subFunctions: [
                            { label: "Foreign aid", allocation: 3731, change: 4278, projection: 3675 },
                            { label: "Diplomacy", allocation: 1322, change: 1275, projection: 1327 },
                            { label: "Payments to international organisations", allocation: 419, change: 471, projection: 428 },
                            { label: "Passport services", allocation: 265, change: 268, projection: 268 },
                            { label: "International police assistance", allocation: 164, change: 201, projection: 158 },
                            { label: "Intl. agriculture research & dev", allocation: 106, change: 109, projection: 103 },
                            { label: "Consular services", allocation: 181, change: 199, projection: 130 },
                            { label: "Finance & insurance for exporters & investors", allocation: 9, change: 13, projection: 8 },
                            { label: "Other", allocation: 143, change: 135, projection: 107 },
                        ]
                    },
                    {
                        label: "General research", allocation: 3448, change: 6949, projection: 6024,
                        subFunctions: [
                            { label: "Research - science services & innovation fund", allocation: 1407, change: 1374, projection: 1413 },
                            { label: "Discovery - research and research training", allocation: 490, change: 484, projection: 496 },
                            { label: "Science & technology solutions", allocation: 374, change: 366, projection: 379 },
                            { label: "Linkage - cross sector research partnerships", allocation: 327, change: 325, projection: 332 },
                            { label: "Supporting science and commercialisation", allocation: 430, change: 294, projection: 389 },
                            { label: "Research capacity", allocation: 305, change: 285, projection: 320},
                            { label: "Other", allocation: 115, change: 110, projection: 120 },
                        ]
                    },
                    { label: "General services", allocation: 771, change: 1237, projection: 748 },
                    { label: "Government superannuation benefits", allocation: 5700, change: 11153, projection: 5810 },
                ]
            },
            {
                label: "Defence", allocation: 34473, change: 33375, projection: 36496,
            },
            {
                label: "Public order and safety", allocation: 6652, change: 6712, projection: 6074,
                subFunctions: [
                    { label: "Courts and legal services", allocation: 1633, change: 1529, projection: 1518 },
                    { label: "Policing and law enforcement", allocation: 3594, change: 3802, projection: 3287 },
                    { label: "Border protection", allocation: 1425, change: 1382, projection: 1269 },
                ]
            },
            {
                label: "Education", allocation: 42799, change: 42604, projection: 43496,
                subFunctions: [
                    { label: "Higher education", allocation: 10628, change: 11369, projection: 10241 },
                    { label: "Vocational and other education", allocation: 2022, change: 2224, projection: 1805 },
                    { label: "Non-government schools", allocation: 24437, change: 22061, projection: 25933 },
                    { label: "Government schools", allocation: 14710, change: 13010, projection: 15509 },
                    { label: "School education - specific funding", allocation: 744, change: 705, projection: 679 },
                    { label: "Student assistance", allocation: 4686, change: 5953, projection: 4570 },
                    { label: "General administration", allocation: 282, change: 292, projection: 268 },
                ]
            },
            {
                label: "Health", allocation: 98283, change: 94533, projection: 95779,
                subFunctions: [
                    {
                        label: "Medical services and benefits", allocation: 37551, change: 36841, projection: 38352,
                        subFunctions: [
                            { label: "Medical benefits", allocation: 29124, change: 28497, projection: 29867 },
                            { label: "Private health insurance", allocation: 6747, change: 6657, projection: 6864 },
                            { label: "General medical consultations and services", allocation: 743, change: 776, projection: 700 },
                            { label: "Dental services", allocation: 338, change: 337, projection: 343 },
                            { label: "Other", allocation: 600, change: 573, projection: 578 },
                        ]
                    },
                    {
                        label: "Pharmaceutical benefits and services", allocation: 15208, change: 14762, projection: 15375,
                        subFunctions: [
                            { label: "Pharmaceutical benefits, services and supply ", allocation: 14406, change: 13942, projection: 14578 },
                            { label: "Immunisation", allocation: 479, change: 475, projection: 473 },
                            { label: "Veterans' pharmaceutical benefits", allocation: 323, change: 345, projection: 323 },
                        ]
                    },
                    { label: "Assistance to the States for public hospitals", allocation: 25462, change: 22646, projection: 26649 },
                    { label: "Hospital services", allocation: 1195, change: 1143, projection: 1147 },
                    { label: "Health services", allocation: 13653, change: 14130, projection: 9755 },
                    { label: "General administration", allocation: 4233, change: 4036, projection: 3491 },
                    { label: "Aboriginal and Torres Strait Islander health", allocation: 980, change: 975, projection: 1011 },
                ]
            },
            {
                label: "Social security and welfare", allocation: 209975, change: 225394, projection: 214655,
                subFunctions: [
                    {
                        label: "Assistance to the aged", allocation: 77166, change: 78100, projection: 82644,
                        subFunctions: [
                            { label: "Income Support for Seniors", allocation: 51223, change: 53544, projection: 53163 },
                            { label: "Aged Care Services", allocation: 24159, change: 22533, projection: 27621 },
                            { label: "Veterans' Community Care and Support", allocation: 915, change: 965, projection: 848 },
                            { label: "Aged Care Quality", allocation: 292, change: 644, projection: 372 },
                            { label: "Access and information", allocation: 363, change: 276, projection: 446 },
                            // { label: "Mature Age Income Support", allocation: 87, change: 152, projection: 9 },
                            // { label: "Allowances, concessions & services for seniors", allocation: 570, change: 379, projection: 79 },
                            { label: "National Partnership Payments - Assistance to the Aged", allocation: 13, change: 20, projection: 19 },
                            { label: "Other", allocation: 200, change: 122, projection: 176 },
                        ]
                    },
                    { label: "Assistance to veterans and dependants", allocation: 7962, change: 78100, projection: 82644 },
                    { label: "Assistance to people with disabilities", allocation: 58779, change: 56881, projection: 60854,
                        subFunctions:
                            [
                                // { label: "Income Support for People with Disability", allocation: 18506, change: 17781, projection: 17328 },
                                { label: "National Disability Insurance Scheme", allocation: 28155, change: 24607, projection: 29835 },
                                { label: "Financial Support for disabled", allocation: 17763, change: 18411, projection: 18073 },
                                { label: "Financial Support for Carers", allocation: 11552, change: 11516, projection: 11860 },
                                

                                // { label: "Assistance to the States for Disability Services", allocation: 0, change: 174, projection: 0 },
                                // { label: "Disability and Carers", allocation: 1771, change: 1449, projection: 1811 },
                                // { label: "National Disability Insurance Scheme Transition Programme", allocation: 219, change: 500, projection: 37 },
                                { label: "National Partnership Payments - Assistance to People with Disabilities", allocation: 1350, change: 2347, projection: 1087 },
                            ]
                    },

                    { label: "Assistance to families with children", allocation: 39573, change: 42252, projection: 40467,
                        subFunctions: [
                            { label: "Family assistance", allocation: 21009, change: 21109, projection: 20707 },
                            { label: "Child Care Subsidy", allocation: 9492, change: 8968, projection: 10644 },
                            { label: "Parents income support", allocation: 5755, change: 7932, projection: 5757 },
                            // { label: "Paid Parental Leave", allocation: 2103, change: 2082, projection: 2127 },
                            { label: "Child support", allocation: 2013, change: 2082, projection: 2127 },
                            { label: "Support for the child care system", allocation: 279, change: 1336, projection: 282 },
                            { label: "Families and Children", allocation: 689, change: 626, projection: 692 },
                            { label: "Family relationship services", allocation: 229, change: 182, projection: 241 },
                            // { label: "Child Payments", allocation: 96, change: 106, projection: 96 },
                            { label: "Other", allocation: 16, change: 16, projection: 17 },
                        ]
                    },
                    { label: "Assistance to the unemployed and the sick", allocation: 18099, change: 31266, projection: 15135 },
                    { label: "Other welfare programs", allocation: 1646, change: 1847, projection: 1561 },
                    { label: "Assistance for Indigenous Australians nec", allocation: 2522, change: 2431, projection: 2347 },
                    { label: "General administration", allocation: 4229, change: 4567, projection: 3576 },
                ]
            },
            {
                label: "Housing and community amenities", allocation: 7869, change: 6952, projection: 6283,
                subFunctions: [
                    { label: "Housing", allocation: 4418, change: 3735, projection: 3185 },
                    { label: "Urban and regional development", allocation: 1606, change: 1476, projection: 1254 },
                    { label: "Environment protection", allocation: 1844, change: 1743, projection: 1845 },
                ]
            },
            {
                label: "Recreation and culture", allocation: 4532, change: 4405, projection: 4151,
                subFunctions: [
                    {
                        label: "Broadcasting", allocation: 1524, change: 1507, projection: 1515,
                        subFunction: [
                            { label: "ABC general operational activities", allocation: 900, change: 901, projection: 891 },
                            { label: "SBS general operational activities", allocation: 357, change: 343, projection: 353 },
                            { label: "ABC transmission and distribution services", allocation: 192, change: 190, projection: 193 },
                            { label: "SBS transmission and distribution services", allocation: 75, change: 73, projection: 78 },
                        ]
                    },
                    { label: "Arts and cultural heritage", allocation: 1914, change: 1699, projection: 1672 },
                    { label: "Sport and recreation", allocation: 543, change: 615, projection: 478 },
                    { label: "National estate and parks", allocation: 550, change: 584, projection: 486 },
                ]
            },
            {
                label: "Fuel and energy", allocation: 9638, change: 9090, projection: 9546,
                subFunctions: [
                    { label: "Fuel Tax Credits Scheme", allocation: 8072, change: 7623, projection: 8450 },
                    { label: "Resources and Energy", allocation: 424, change: 756, projection: 170 },
                    { label: "Renewable Energy", allocation: 373, change: 298, projection: 281 },
                    { label: "Other", allocation: 769, change: 413, projection: 645 },
                ]
            },
            {
                label: "Agriculture, forestry and fishing", allocation: 4483, change: 4014, projection: 3779,
                subFunctions: [
                    { label: "Wool industry", allocation: 53, change: 50, projection: 56 },
                    { label: "Grains industry", allocation: 211, change: 204, projection: 206 },
                    { label: "Dairy industry", allocation: 53, change: 53, projection: 53 },
                    { label: "Cattle, sheep and pig industry", allocation: 250, change: 244, projection: 254 },
                    { label: "Fishing, horticulture and other agriculture", allocation: 402, change: 483, projection: 352 },
                    { label: "General assistance not allocated to specific industries", allocation: 39, change: 44, projection: 39 },
                    { label: "Rural assistance", allocation: 553, change: 1170, projection: 448 },
                    {
                        label: "Natural resources development", allocation: 1940, change: 859, projection: 1447,
                        subFunctions: [
                            { label: "Water reform", allocation: 1539, change: 573, projection: 1130 },
                            { label: "Sustainable management - natural resources", allocation: 71, change: 15, projection: 84 },
                            { label: "Other", allocation: 330, change: 271, projection: 233 },
                        ]
                    },
                    { label: "General administration", allocation: 982, change: 906, projection: 925 },
                ]
            },
            {
                label: "Mining, manufacturing and construction", allocation: 4354, change: 4394, projection: 4230, subFunctions:
                    [
                        { label: "Research and Development Tax Incentive", allocation: 2729, change: 3085, projection: 2759 },
                        { label: "Growing Business Investment", allocation: 726, change: 384, projection: 850 },
                        { label: "Northern Australia Infrastructure Facility", allocation: 549, change: 671, projection: 314 },
                        { label: "Other", allocation: 351, change: 254, projection: 308},
                    ]
            },
            {
                label: "Transport and communication", allocation: 14460, change: 13828, projection: 17618,
                subFunctions: [
                    { label: "Communication", allocation: 1708, change: 1244, projection: 1603 },
                    { label: "Rail transport", allocation: 2805, change: 1376, projection: 3436 },
                    { label: "Air transport", allocation: 1020, change: 2732, projection: 348 },
                    { label: "Road transport", allocation: 8187, change: 7765, projection: 11473 },
                    { label: "Sea transport", allocation: 462, change: 458, projection: 463 },
                    { label: "Other transport and communication", allocation: 277, change: 253, projection: 296 }
                ]
            },
            {
                label: "Other economic affairs", allocation: 14640, change: 83819, projection: 11091,
                subFunctions: [
                    { label: "Tourism and area promotion", allocation: 199, change: 227, projection: 160 },
                    { label: "Vocational and industry training", allocation: 3737, change: 3061, projection: 2430 },
                    { label: "Labour market assistance to job seekers & industry", allocation: 2945, change: 2687, projection: 2376 },
                    { label: "Industrial relations", allocation: 848, change: 798, projection: 735 },
                    {
                        label: "Immigration", allocation: 3694, change: 3622, projection: 2683,
                        subFunction: [
                            { label: "Management of unlawful non-citizens", allocation: 2059, change: 2091, projection: 1284 },
                            { label: "Citizenship, visas & migration", allocation: 799, change: 821, projection: 693 },
                            { label: "Regional co-operation, refugee & humanitarian assistance", allocation: 835, change: 710, projection: 706 },
                        ]
                    },
                    {
                        label: "Other economic affairs", allocation: 3216, change: 73424, projection: 2708,
                        subFunction: [
                            { label: "Economic Response to the Coronavirus", allocation: 47, change: 69434, projection: 12 },
                            { label: "Promotion of Australia's export & other international economic interests", allocation: 632, change: 1439, projection: 360 },
                            { label: "Department of Industry, Innovation and Science", allocation: 535, change: 538, projection: 509 },
                            { label: "Australian Securities & Investments Commission", allocation: 563, change: 677, projection: 536 },
                            { label: "Bureau of Meteorology", allocation: 527, change: 481, projection: 531 },
                            { label: "IP Australia ", allocation: 216, change: 202, projection: 224 },
                            { label: "Australian Competition & Consumer Commission", allocation: 206, change: 200, projection: 189 },
                            { label: "Australian Prudential Regulation Authority", allocation: 226, change: 196, projection: 206 },
                            { label: "National Partnership Payments - Competition & Productivity Enhancing Reform", allocation: 127, change: 134, projection: 0 },
                            { label: "Other", allocation: 138, change: 123, projection: 140 },
                        ]
                    },
                ]
            },
            {
                label: "Other purposes", allocation: 111106, change: 97279, projection: 117368,
                subFunctions: [
                    { label: "Public debt interest", allocation: 18196, change: 17123, projection: 19118 },
                    { label: "Nominal superannuation interest", allocation: 10018, change: 7004, projection: 10775 },
                    { label: "General revenue assistance - States & Territories", allocation: 75188, change: 71729, projection: 78564 },
                    { label: "Local government assistance", allocation: 2321, change: 3520, projection: 3321 },
                    { label: "Natural disaster relief", allocation: 327, change: 748, projection: 253 },
                    { label: "Contingency reserve", allocation: 5055, change: -2845, projection: 5338 },
                ]
            },



            {
                label: "Capital Investment", allocation: 10330, change: 8620, projection: 10939,
                subFunctions: [
                    { label: "General public services", allocation: 597, change: 421, projection: 250 },
                    { label: "Defence", allocation: 8965, change: 5868, projection: 10686 },
                    { label: "Public order and safety", allocation: 315, change: 312, projection: -282 },
                    { label: "Education", allocation: 42, change: 34, projection: 13 },
                    { label: "Health", allocation: 1136, change: 875, projection: -19 },
                    { label: "Social security and welfare", allocation: 14, change: 1991, projection: -36 },
                    { label: "Housing and community amenities", allocation: -128, change: -242, projection: 55 },
                    { label: "Recreation and culture", allocation: 257, change: 111, projection: 226 },
                    { label: "Fuel and energy", allocation: 21, change: 21, projection: 14 },
                    { label: "Agriculture, forestry and fishing", allocation: 68, change: 41, projection: 63 },
                    { label: "Mining, manufacturing and construction", allocation: -24, change: -22, projection: -12 },
                    { label: "Transport and communication", allocation: -25, change: -4, projection: -36 },
                    { label: "Other economic affairs", allocation: 297, change: 478, projection: -231 },
                    { label: "Other purposes", allocation: 58, change: 2, projection: 15 },
                ]
            },


            {
                label: "Purchase of Assets", allocation: 22325, change: 18318, projection: 23202,
                subFunctions: [
                    { label: "General public services", allocation: 2244, change: 1951, projection: 1788 },
                    { label: "Defence", allocation: 15207, change: 11951, projection: 17507 },
                    { label: "Public order and safety", allocation: 1155, change: 1105, projection: 558 },
                    { label: "Education", allocation: 83, change: 74, projection: 59 },
                    { label: "Health", allocation: 250, change: 268, projection: 193 },
                    { label: "Social security and welfare", allocation: 751, change: 444, projection: 1008 },
                    { label: "Housing and community amenities", allocation: 343, change: 336, projection: 421 },
                    { label: "Recreation and culture", allocation: 716, change: 544, projection: 682 },
                    { label: "Fuel and energy", allocation: 33, change: 15, projection: 25 },
                    { label: "Agriculture, forestry and fishing", allocation: 162, change: 134, projection: 157 },
                    { label: "Mining, manufacturing and construction", allocation: 14, change: 16, projection: 27 },
                    { label: "Transport and communication", allocation: 107, change: 123, projection: 100 },
                    { label: "Other economic affairs", allocation: 1188, change: 1353, projection: 661 },
                    { label: "Other purposes", allocation: 75, change: 3, projection: 17 },
                ]
            },

        ]
    };
    




                                                             
//     .--~*teu.        .n~~%x.       .--~*teu.      .x~~"*Weu.   
//     dF     988Nx    x88X   888.    dF     988Nx   d8Nu.  9888c  
//    d888b   `8888>  X888X   8888L  d888b   `8888>  88888  98888  
//    ?8888>  98888F X8888X   88888  ?8888>  98888F  "***"  9888%  
//     "**"  x88888~ 88888X   88888X  "**"  x88888~       ..@8*"   
//          d8888*`  88888X   88888X       d8888*`     ````"8Weu   
//        z8**"`   : 88888X   88888f     z8**"`   :   ..    ?8888L 
//      :?.....  ..F 48888X   88888    :?.....  ..F :@88N   '8888N 
//     <""888888888~  ?888X   8888"   <""888888888~ *8888~  '8888F 
//     8:  "888888*    "88X   88*`    8:  "888888*  '*8"`   9888%  
//     ""    "**"`       ^"==="`      ""    "**"`     `~===*%"`    
                                                                
           




    export const taxDistribution2023 = {
        year: 2023,
        total: 734518,
        change: 691070, 
        projection: 767290,
        functions: [
            {
                label: "General public services", allocation: 32395, change: 31442, projection: 30595,
                subFunctions: [
                    { label: "Legislative and executive affairs", allocation: 2216, change: 2052, projection: 1676 },
                    { label: "Financial and fiscal affairs", allocation: 10227, change: 10378, projection: 10070 },
                    {
                        label: "Foreign affairs and economic aid", allocation: 8730, change: 7282, projection: 7667,
                        subFunctions: [
                            { label: "Foreign aid", allocation: 5222, change: 4080, projection: 4229 },
                            { label: "Diplomacy", allocation: 1645, change: 1494, projection: 1689 },
                            { label: "Payments to international organisations", allocation: 460, change: 459, projection: 460 },
                            { label: "Passport services", allocation: 394, change: 390, projection: 307 },
                            { label: "International police assistance", allocation: 280, change: 259, projection: 281 },
                            { label: "Intl. agriculture research & dev", allocation: 129, change: 127, projection: 126 },
                            { label: "Consular services", allocation: 160, change: 146, projection: 145 },
                            { label: "Finance & insurance for exporters & investors", allocation: 258, change: 171, projection: 245 },
                            { label: "Other", allocation: 183, change: 156, projection: 184 },
                        ]
                    },
                    {
                        label: "General research", allocation: 4485, change: 4063, projection: 4378,
                        subFunctions: [
                            { label: "Research - science services & innovation fund", allocation: 1537, change: 1620, projection: 1563 },
                            { label: "Discovery - research and research training", allocation: 630, change: 525, projection: 630 },
                            { label: "Science & technology solutions", allocation: 495, change: 473, projection: 525 },
                            { label: "Linkage - cross sector research partnerships", allocation: 398, change: 329, projection: 407 },
                            { label: "Supporting science and commercialisation", allocation: 480, change: 361, projection: 325 },
                            { label: "Research capacity", allocation: 781, change: 581, projection: 755},
                            { label: "Other", allocation: 164, change: 174, projection: 173 },
                        ]
                    },
                    { label: "General services", allocation: 1253, change: 1260, projection: 1221 },
                    { label: "Government superannuation benefits", allocation: 5485, change: 6407, projection: 5584 },
                ]
            },
            {
                label: "Defence", allocation: 47986, change: 45128, projection: 50046,
            },
            {
                label: "Public order and safety", allocation: 8421, change: 7960, projection: 6993,
                subFunctions: [
                    { label: "Courts and legal services", allocation: 1894, change: 1827, projection: 1268 },
                    { label: "Policing and law enforcement", allocation: 4571, change: 4370, projection: 4213 },
                    { label: "Border protection", allocation: 1957, change: 1762, projection: 1512 },
                ]
            },
            {
                label: "Education", allocation: 53046, change: 49099, projection: 53220,
                subFunctions: [
                    { label: "Higher education", allocation: 11540, change: 10918, projection: 12113 },
                    { label: "Vocational and other education", allocation: 2540, change: 2351, projection: 2654 },
                    { label: "Non-government schools", allocation: 30201, change: 29215, projection: 31385 },
                    { label: "Government schools", allocation: 18726, change: 18116, projection: 19497 },
                    { label: "School education - specific funding", allocation: 940, change: 1182, projection: 895 },
                    { label: "Student assistance", allocation: 7482, change: 5132, projection: 5863 },
                    { label: "General administration", allocation: 343, change: 301, projection: 309 },
                ]
            },
            {
                label: "Health", allocation: 112693, change: 107416, projection: 115913,
                subFunctions: [
                    {
                        label: "Medical services and benefits", allocation: 41233, change: 378777, projection: 43341,
                        subFunctions: [
                            { label: "Medical benefits", allocation: 31983, change: 29763, projection: 33915 },
                            { label: "Private health insurance", allocation: 7533, change: 7325, projection: 7750 },
                            { label: "General medical consultations and services", allocation: 715, change: 710, projection: 700 },
                            { label: "Dental services", allocation: 328, change: 340, projection: 326 },
                            { label: "Other", allocation: 674, change: 640, projection: 650 },
                        ]
                    },
                    {
                        label: "Pharmaceutical benefits and services", allocation: 20574, change: 20131, projection: 20740,
                        subFunctions: [
                            { label: "Pharmaceutical benefits, services and supply ", allocation: 19543, change: 19009, projection: 19760 },
                            { label: "Immunisation", allocation: 662, change: 768, projection: 612 },
                            { label: "Veterans' pharmaceutical benefits", allocation: 369, change: 354, projection: 367 },
                        ]
                    },
                    { label: "Assistance to the States for public hospitals", allocation: 30149, change: 27853, projection: 32187 },
                    { label: "Hospital services", allocation: 1130, change: 1100, projection: 1189 },
                    { label: "Health services", allocation: 13363, change: 13860, projection: 12715 },
                    { label: "General administration", allocation: 4900, change: 4460, projection: 4434 },
                    { label: "Aboriginal and Torres Strait Islander health", allocation: 1344, change: 1235, projection: 1308 },
                ]
            },
            {
                label: "Social security and welfare", allocation: 266693, change: 252342, projection: 282057,
                subFunctions: [
                    {
                        label: "Assistance to the aged", allocation: 100653, change: 95306, projection: 104825,
                        subFunctions: [
                            { label: "Income Support for Seniors", allocation: 61672, change: 59160, projection: 64459 },
                            { label: "Aged Care Services", allocation: 36150, change: 32321, projection: 37863 },
                            { label: "Veterans' Community Care and Support", allocation: 999, change: 1092, projection: 1036 },
                            { label: "Aged Care Quality", allocation: 541, change: 1691, projection: 303 },
                            { label: "Access and information", allocation: 739, change: 714, projection: 728 },
                            // { label: "Mature Age Income Support", allocation: 87, change: 152, projection: 9 },
                            // { label: "Allowances, concessions & services for seniors", allocation: 570, change: 379, projection: 79 },
                            { label: "National Partnership Payments - Assistance to the Aged", allocation: 216, change: 20, projection: 190 },
                            { label: "Other", allocation: 336, change: 309, projection: 245 },
                        ]
                    },
                    { label: "Assistance to veterans and dependants", allocation: 7982, change: 7982, projection: 12191 },
                    { label: "Assistance to people with disabilities", allocation: 84342, change: 44381, projection: 49115,
                        subFunctions:
                            [
                                // { label: "Income Support for People with Disability", allocation: 18506, change: 17781, projection: 17328 },
                                { label: "National Disability Insurance Scheme", allocation: 48913, change: 44463, projection: 52430 },
                                { label: "Financial Support for disabled", allocation: 22003, change: 21165, projection: 22855 },
                                { label: "Financial Support for Carers", allocation: 13419, change: 12752, projection: 13855 },
                                

                                // { label: "Assistance to the States for Disability Services", allocation: 0, change: 174, projection: 0 },
                                // { label: "Disability and Carers", allocation: 1771, change: 1449, projection: 1811 },
                                // { label: "National Disability Insurance Scheme Transition Programme", allocation: 219, change: 500, projection: 37 },
                                { label: "National Partnership Payments - Assistance to People with Disabilities", allocation: 7, change: 2022, projection: 0 },
                            ]
                    },

                    { label: "Assistance to families with children", allocation: 46931, change: 44381, projection: 49115,
                        subFunctions: [
                            { label: "Family assistance", allocation: 20742, change: 19787, projection: 22107 },
                            { label: "Child Care Subsidy", allocation: 14531, change: 13914, projection: 15113 },
                            { label: "Parents income support", allocation: 7911, change: 7223, projection: 8209 },
                            // { label: "Paid Parental Leave", allocation: 2103, change: 2082, projection: 2127 },
                            { label: "Child support", allocation: 1923, change: 1893, projection: 1963 },
                            { label: "Support for the child care system", allocation: 460, change: 412, projection: 333 },
                            { label: "Families and Children", allocation: 1065, change: 861, projection: 1087 },
                            { label: "Family relationship services", allocation: 275, change: 267, projection: 281 },
                            // { label: "Child Payments", allocation: 96, change: 106, projection: 96 },
                            { label: "Other", allocation: 23, change: 24, projection: 21 },
                        ]
                    },
                    { label: "Assistance to the unemployed and the sick", allocation: 16100, change: 14737, projection: 16562 },
                    { label: "Other welfare programs", allocation: 1897, change: 1621, projection: 1844 },
                    { label: "Assistance for Indigenous Australians nec", allocation: 3399, change: 3279, projection: 3191 },
                    { label: "General administration", allocation: 5388, change: 4951, projection: 5089 },
                ]
            },
            {
                label: "Housing and community amenities", allocation: 9999, change: 7955, projection: 8831,
                subFunctions: [
                    { label: "Housing", allocation: 4372, change: 4305, projection: 4206 },
                    { label: "Urban and regional development", allocation: 2906, change: 1620, projection: 2258 },
                    { label: "Environment protection", allocation: 2721, change: 2029, projection: 2367 },
                ]
            },
            {
                label: "Recreation and culture", allocation: 5372, change: 5050, projection: 5472,
                subFunctions: [
                    {
                        label: "Broadcasting", allocation: 1749, change: 1688, projection: 1801,
                        subFunction: [
                            { label: "ABC general operational activities", allocation: 1042, change: 1004, projection: 1060 },
                            { label: "SBS general operational activities", allocation: 421, change: 409, projection: 447 },
                            { label: "ABC transmission and distribution services", allocation: 207, change: 197, projection: 213 },
                            { label: "SBS transmission and distribution services", allocation: 79, change: 77, projection: 81 },
                        ]
                    },
                    { label: "Arts and cultural heritage", allocation: 2140, change: 1992, projection: 1919 },
                    { label: "Sport and recreation", allocation: 708, change: 594, projection: 993 },
                    { label: "National estate and parks", allocation: 774, change: 776, projection: 759 },
                ]
            },
            {
                label: "Fuel and energy", allocation: 13273, change: 20121, projection: 13908,
                subFunctions: [
                    { label: "Fuel Tax Credits Scheme", allocation: 10184, change: 9857, projection: 10561 },
                    { label: "Resources and Energy", allocation: 4464, change: 2093, projection: 1346 },
                    { label: "Renewable Energy", allocation: 5053, change: 973, projection: 1673 },
                    { label: "Other", allocation: 420, change: 350, projection: 328 },
                ]
            },
            {
                label: "Agriculture, forestry and fishing", allocation: 4317, change: 4068, projection: 4079,
                subFunctions: [
                    { label: "Wool industry", allocation: 84, change: 68, projection: 95 },
                    { label: "Grains industry", allocation: 290, change: 272, projection: 292 },
                    { label: "Dairy industry", allocation: 57, change: 58, projection: 55 },
                    { label: "Cattle, sheep and pig industry", allocation: 262, change: 258, projection: 268 },
                    { label: "Fishing, horticulture and other agriculture", allocation: 503, change: 508, projection: 443 },
                    { label: "General assistance not allocated to specific industries", allocation: 47, change: 45, projection: 45 },
                    { label: "Rural assistance", allocation: 316, change: 406, projection: 398 },
                    { label: "Natural resources development", allocation: 1346, change: 1089, projection: 1177,
                        // subFunctions: [
                        //     { label: "Water reform", allocation: 1539, change: 573, projection: 1130 },
                        //     { label: "Sustainable management - natural resources", allocation: 71, change: 15, projection: 84 },
                        //     { label: "Other", allocation: 330, change: 271, projection: 233 },
                        // ]
                    },
                    { label: "General administration", allocation: 1411, change: 1364, projection: 1306 },
                ]
            },
            {
                label: "Mining, manufacturing and construction", allocation: 5511, change: 5968, projection: 5563, subFunctions:
                    [
                        { label: "Research and Development Tax Incentive", allocation: 4475, change: 4783, projection: 4610 },
                        { label: "Growing Business Investment", allocation: 451, change: 684, projection: 241 },
                        { label: "Northern Australia Infrastructure Facility", allocation: 145, change: 98, projection: 275 },
                        { label: "Other", allocation: 440, change: 402, projection: 438},
                    ]
            },
            {
                label: "Transport and communication", allocation: 16769, change: 14928, projection: 16717,
                subFunctions: [
                    { label: "Communication", allocation: 1927, change: 1775, projection: 1825 },
                    { label: "Rail transport", allocation: 3814, change: 3082, projection: 3212 },
                    { label: "Air transport", allocation: 465, change: 434, projection: 446 },
                    { label: "Road transport", allocation: 9707, change: 8859, projection: 10408 },
                    { label: "Sea transport", allocation: 496, change: 499, projection: 496 },
                    { label: "Other transport and communication", allocation: 360, change: 279, projection: 331}
                ]
            },
            {
                label: "Other economic affairs", allocation: 13386, change: 14011, projection: 12214,
                subFunctions: [
                    { label: "Tourism and area promotion", allocation: 192, change: 198, projection: 191 },
                    { label: "Vocational and industry training", allocation: 5881, change: 6308, projection: 5575 },
                    { label: "Labour market assistance to job seekers & industry", allocation: 2384, change: 2984, projection: 2130 },
                    { label: "Industrial relations", allocation: 2411, change: 2326, projection: 2400 },
                    { label: "Immigration", allocation: 3699, change: 3836, projection: 3147,
                        subFunction: [
                            { label: "Management of unlawful non-citizens", allocation: 1732, change: 1779, projection: 1417 },
                            { label: "Citizenship, visas & migration", allocation: 945, change: 975, projection: 818 },
                            { label: "Regional co-operation, refugee & humanitarian assistance", allocation: 1022, change: 1082, projection: 912 },
                        ]
                    },
                    {
                        label: "Other economic affairs", allocation: 3614, change: 3669, projection: 3300,
                        subFunction: [
                            { label: "Economic Response to the Coronavirus", allocation: 0, change: 228, projection: 0 },
                            { label: "Promotion of Australia's export & other international economic interests", allocation: 456, change: 486, projection: 390 },
                            { label: "Department of Industry, Innovation and Science", allocation: 775, change: 755, projection: 706 },
                            { label: "Australian Securities & Investments Commission", allocation: 849, change: 714, projection: 728 },
                            { label: "Bureau of Meteorology", allocation: 522, change: 561, projection: 523 },
                            { label: "IP Australia ", allocation: 269, change: 255, projection: 278 },
                            { label: "Australian Competition & Consumer Commission", allocation: 301, change: 266, projection: 250 },
                            { label: "Australian Prudential Regulation Authority", allocation: 269, change: 239, projection: 265 },
                            { label: "National Partnership Payments - Competition & Productivity Enhancing Reform", allocation: 0, change: 0, projection: 0 },
                            { label: "Other", allocation: 174, change: 164, projection: 160 },
                        ]
                    },
                ]
            },
            {
                label: "Other purposes", allocation: 137810, change: 132430, projection: 161683,
                subFunctions: [
                    { label: "Public debt interest", allocation: 24107, change: 22547, projection: 28269 },
                    { label: "Nominal superannuation interest", allocation: 14620, change: 13374, projection: 15126 },
                    { label: "General revenue assistance - States & Territories", allocation: 97412, change: 92917, projection: 102937 },
                    { label: "Local government assistance", allocation: 3583, change: 810, projection: 3696 },
                    { label: "Natural disaster relief", allocation: 921, change: 1392, projection: 676 },
                    { label: "Contingency reserve", allocation: 167, change: 2200, projection: 14674 },
                ]
            },



            {
                label: "Capital Investment", allocation: 6303, change: 7754, projection: 8055,
                subFunctions: [
                    { label: "General public services", allocation: 423, change: 346, projection: 256 },
                    { label: "Defence", allocation: 6853, change: 7409, projection: 7504 },
                    { label: "Public order and safety", allocation: 28, change: 78, projection: -23 },
                    { label: "Education", allocation: 50, change: 34, projection: 33 },
                    { label: "Health", allocation: 133, change: -123, projection: 182 },
                    { label: "Social security and welfare", allocation: -76, change: 13, projection: -400 },
                    { label: "Housing and community amenities", allocation: 74, change: 68, projection: 88 },
                    { label: "Recreation and culture", allocation: 372, change: 385, projection: 130 },
                    { label: "Fuel and energy", allocation: -1, change: -1, projection: 3 },
                    { label: "Agriculture, forestry and fishing", allocation: 539, change: 212, projection: 297 },
                    { label: "Mining, manufacturing and construction", allocation: -9, change: -15, projection: -10 },
                    { label: "Transport and communication", allocation: -2107, change: -625, projection: -35 },
                    { label: "Other economic affairs", allocation: -53, change: -34, projection: -182 },
                    { label: "Other purposes", allocation: 75, change: 8, projection: 212 },
                ]
            },


            {
                label: "Purchase of Assets", allocation: 21960, change: 21771, projection: 20556,
                subFunctions: [
                    { label: "General public services", allocation: 2243, change: 1888, projection: 1872 },
                    { label: "Defence", allocation: 14292, change: 14805, projection: 14540 },
                    { label: "Public order and safety", allocation: 894, change: 944, projection: 837 },
                    { label: "Education", allocation: 65, change: 51, projection: 52 },
                    { label: "Health", allocation: 550, change: 395, projection: 219 },
                    { label: "Social security and welfare", allocation: 803, change: 919, projection: 443 },
                    { label: "Housing and community amenities", allocation: 524, change: 507, projection: 507 },
                    { label: "Recreation and culture", allocation: 848, change: 849, projection: 618 },
                    { label: "Fuel and energy", allocation: 11, change: 9, projection: 16 },
                    { label: "Agriculture, forestry and fishing", allocation: 670, change: 320, projection: 418 },
                    { label: "Mining, manufacturing and construction", allocation: 33, change: 25, projection: 33 },
                    { label: "Transport and communication", allocation: 102, change: 221, projection: 80 },
                    { label: "Other economic affairs", allocation: 846, change: 823, projection: 708 },
                    { label: "Other purposes", allocation: 79, change: 13, projection: 214 },
                ]
            },

        ]
    };
    