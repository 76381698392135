import 'core-js/features/array/find-index';
import 'core-js/features/array/find';
import 'core-js/features/object/assign';

import React, { useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Workbox } from 'workbox-window';
import ResizeObserver from 'resize-observer-polyfill';

import { CalculatorProvider, readData } from './state/calculatorState';
import { ChangeThemeProvider, readTheme } from './state/themeState';
import { LayoutProvider, readLayout, useLayout, START_PRINTING, END_PRINTING } from './state/layoutState';


import { Route, Routes } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useTheme, Box } from '@material-ui/core';
import loadable from '@loadable/component'

import CalculatorPage from './pages/CalculatorPage';
import NavBar from './components/nav/Navbar';

// import './styles/App.css';
import { CALCULATOR_LINK, TAX_INFO_LINK, STUDENT_LOAN_LINK, PRIVACY_POLICY_LINK, ACCEPTABLE_USE_LINK, COOKIES_LINK, TAX_DISTRIBUTION_LINK } from './constants';

const PrivacyPolicyPage = loadable(() => import('./pages/privacyPolicyPage'))
const AcceptableUsePage = loadable(() => import('./pages/AcceptableUsePage'))
const CookiesPage = loadable(() => import('./pages/CookiesPage'))
const TaxInfoPage = loadable(() => import('./pages/TaxInfoPage'))
const DistributionPage = loadable(() => import('./pages/DistributionPage'))
const StudentLoansPage = loadable(() => import('./pages/StudentLoans'))


export const PrintableContext = React.createContext({});

//A polyfill for the Resize Observer API.
if (!window.ResizeObserver) {
  window.ResizeObserver = (ResizeObserver).default;
}


const serviceworker = async () => {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('./service-worker.js');
    wb.register();

    wb.addEventListener('waiting', (event) => {
      showSkipWaitingPrompt(event);
    });

    // ref: https://developer.chrome.com/docs/workbox/handling-service-worker-updates/
    const showSkipWaitingPrompt = (event) => {
      wb.addEventListener('controlling', () => {
        console.log("RELOADING")
        window.location.reload();
      });

      // forece update
      wb.messageSkipWaiting();
    };
    const swVersion = await wb.messageSW({ type: 'GET_VERSION' });
    console.log('Service Worker version:', swVersion);

  }
}

//-------------------------------------------------
// APP 
//-------------------------------------------------

const App = () => {
  serviceworker()
  return (
    // <Box style={{ overflowX: "hidden"}}>
    <ChangeThemeProvider initialState={readTheme()} >
      <CalculatorProvider initialState={readData()}>
        <LayoutProvider initialState={readLayout()}>
          <AppWrapper />
        </LayoutProvider>
      </CalculatorProvider>
    </ChangeThemeProvider>
    // </Box>
  )
}


//-------------------------------------------------
// APP WRAPPER WITH PRINT HANDLER
//-------------------------------------------------

const AppWrapper = () => {
  const [{ printing }, dispatchLayout] = useLayout();
  const componentRef = useRef();
  // We store the resolve Promise being used in `onBeforeGetContent` here
  const promiseResolveRef = useRef(null);

  const theme = useTheme();
  const style = {
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  };


  useEffect(() => {
    if (printing && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [printing]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,

    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        dispatchLayout({ type: START_PRINTING });
        promiseResolveRef.current = resolve;
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      dispatchLayout({ type: END_PRINTING });
    }
  });

  return (
    <PrintableContext.Provider value={handlePrint}>
      <div id="AppPrintable" ref={componentRef}>
        <CssBaseline />
        <NavBar />
        <Routes>
          
        <Route  exact path={CALCULATOR_LINK}  element={ <CalculatorPage style={style} /> } />
        <Route  exact path={TAX_INFO_LINK}  element={ <TaxInfoPage style={style} /> } />
        <Route  exact path={STUDENT_LOAN_LINK}  element={ <StudentLoansPage style={style} /> } />
        <Route  exact path={TAX_DISTRIBUTION_LINK}  element={ <DistributionPage style={style} /> } />
        <Route  exact path={PRIVACY_POLICY_LINK}  element={ <PrivacyPolicyPage style={style} /> } />
        <Route  exact path={ACCEPTABLE_USE_LINK}  element={ <AcceptableUsePage style={style} /> } />
        <Route  exact path={COOKIES_LINK}  element={ <CookiesPage style={style} /> } />

        {/* <Route  exact path={'/classic'}  element={  window.location.href = '/classic/index.html';
            return null; } /> */}
        <Route  exact path={'/test'}  element={ <CalculatorPage style={style} /> } />
        <Route  exact path={'/:id'}  element={ <CalculatorPage style={style} /> } />

          {/* <Route exact path={CALCULATOR_LINK} render={(props) => {
            return <CalculatorPage style={style} {...props} />
          }}
          /> 
          <Route exact path={TAX_INFO_LINK} render={() => {
            return <TaxInfoPage style={style} />
          }}
          />
          <Route exact path={STUDENT_LOAN_LINK} render={() => {
            return <StudentLoansPage style={style} />
          }}
          />

          <Route exact path={TAX_DISTRIBUTION_LINK} render={() => {
            return <DistributionPage style={style} />
          }}
          />

          <Route exact path={PRIVACY_POLICY_LINK} render={() => {
            return <PrivacyPolicyPage style={style} />
          }}
          />

          <Route exact path={ACCEPTABLE_USE_LINK} render={() => {
            return <AcceptableUsePage style={style} />
          }}
          />

          <Route exact path={COOKIES_LINK} render={() => {
            return <CookiesPage style={style} />
          }}
          />

          <Route path='/classic' component={() => {
            window.location.href = '/classic/index.html';
            return null;
          }} />

          <Route path='/test' render={(props) => {
            return <CalculatorPage {...props} style={style} />
          }}
          />

          <Route path='/:id' render={(props) => {
            return <CalculatorPage {...props} style={style} />
          }}
          />
          */}
        </Routes>
      </div>
    </PrintableContext.Provider>
  )
}


export default (App);
