import { InitialState } from './calculatorConstants';
import { taxData } from './calculatorData';

// match data against the InitialState data
// any additional fields are discarded
// any missing fields are prefilled with InitialState values
export const cleanCalculatorData = (data) => {
    let cleanData = {};
    Object.keys(InitialState).map(function (key) {
        cleanData[key] = data[key] ? data[key] : InitialState[key];
        return true;
    });
    return cleanData;
}


export const getTaxData = (year) => {
	const yearIndex = getTaxYearIndex(year);
	return taxData[yearIndex] || InitialState;
}

//export let currentTaxData = InitialState;


export const getCurrentYear = () => {
	const yearIndex = getCurrentTaxYearIndex();
	const year = taxData[yearIndex].year;
	return { year };
}


// No longer saving the year Index in the calculator state
// Instead this method finds the index or default to initial state or finally the current tax year
export const getTaxYearIndex = (year) => {
	// guard against incorrect or unknown year
	let taxYear = year ? year : InitialState.year;
	const yearIndex = taxData.findIndex((obj) => {
		return (obj.year.toString() === taxYear.toString())
	})
	if (yearIndex >= 0) return yearIndex;

	// if year index is undefined, use the current year
	return getCurrentTaxYearIndex();
}

export const getCurrentTaxYearIndex = () => {
	return taxData.findIndex(t => t.current === "true");

}

export const roundToNearestCent = (value) => {
	//return Math.round(value * 100) / 100;
	return Math.round((value + Number.EPSILON) * 100) / 100;
}

export const ignoreCents = (value) => {
	return Math.floor(value * 1);
}

export const roundToNearestDollar = (value) => {
	return Math.round(value * 1);
}

export const roundToNearestDollerPlus99 = (value) => {
	return Math.floor(value * 1) + 0.99;
}

export const formatMoney = (number, decPlaces, decSep, thouSep) => {

	// decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
	// decSep = typeof decSep === "undefined" ? "." : decSep;
	// thouSep = typeof thouSep === "undefined" ? "," : thouSep;
	// let sign = number < 0 ? "-" : "";
	// let value = 0

	// // i is dollar part of price
	// let i = String(parseInt(value = Math.abs(Number(number) || 0).toFixed(decPlaces)));
	// // j is the length of the "dollar" part of the number
	// let j = (i.length > 3) ? i.length - 3 : 0;

	// let str = sign;
	// str += (j ? i.substr(0, j) + thouSep : "")
	// str += i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep)
	// str += (decPlaces ? decSep + Math.abs(value - i).toFixed(decPlaces).slice(2) : "");

	// return str;

	const decimal_places = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
	const number_value = Number(Number(number).toFixed(decimal_places));
    const formattedNumber = number_value.toLocaleString();
    return formattedNumber.toString()
}

export const isUndefined = (param) => {
    return typeof param === 'undefined' || (typeof param === 'number' && Number.isNaN(param)) || param === null
}
