import React from 'react';
import { OLD_CALCULATOR_LINK, EMAIL_ADDRESS } from '../constants';
import { reset } from '../state/calculatorState';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.toString() };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, info);
  }



  render() {
    const link = (e, url) => {
      e.preventDefault();
      window.location.href = url;
    }

    const hardRefersh = (e) => {
      e.preventDefault();
      window.location.reload(true)
    }


    if (this.state.hasError) {
      // You can render any custom fallback UI
      reset();
      return (
        <>
          <h1>Something went wrong.</h1>
          {this.state.error && <p>{JSON.stringify(this.state.error)}</p>}
          {/* <p>{JSON.stringify(info)}</p> */}

          <p>Click here to <button onClick={(e) => hardRefersh(e)}>refresh</button> the page. If the problem persists please <button onClick={(e) => link(e, EMAIL_ADDRESS) }>report</button> this error</p>
          <p>Continue with the <button onClick={(e) => link(e, OLD_CALCULATOR_LINK)}>Original website</button></p>
        </>
      );
    }

    return this.props.children;
  }
}


const logErrorToMyService = (error, info) => {
            console.log("logErrorToMyService ", error, info)
          }

export default ErrorBoundary;