import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
    Box,
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
} from '@material-ui/core'
import {
    Menu as MenuIcon,
    Keyboard as KeyboardIcon,
    Help as HelpIcon,
    School as SchoolIcon,
    History as HistoryIcon,
    DonutLarge as DonutLargeIcon,

} from '@material-ui/icons';

import { OutIcon } from '../../utils/icons';


import { makeStyles, useTheme } from '@material-ui/core/styles';
import { matchPath } from '../../utils/utils';
import ThemeSwitch from './ThemeSwitch';
import MM from './MM';
import Logo from './Logo';
import history from "../../history";

import {
    MORTAGE_MONSTER,
    CALCULATOR_LINK,
    TAX_INFO_LINK,
    TAX_DISTRIBUTION_LINK,
    STUDENT_LOAN_LINK,
    OLD_CALCULATOR_LINK,
    PRIVACY_POLICY_LINK,
    ACCEPTABLE_USE_LINK,
    COOKIES_LINK,
    EMAIL_ADDRESS,

    ID_SUMMARY,
    ID_MORE_INFO,
} from '../../constants';
import { useLayout } from '../../state/layoutState'
import MiniInput from './MiniInput'


const useStyles = makeStyles(theme => {
    return {
        list: {
            width: 250,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: 1,
            height: '100%'
        },
        drawerPaper: {
        },
        appBar: {
            backgroundColor: theme.palette.primary.main,
            padding: 0,
            margin: 0,
            transition: theme.transitions.create(
                ['all'],
                { duration: theme.transitions.duration.complex }
            ),
        },
        nav: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: 0,
            transition: theme.transitions.create(
                ['all'],
                { duration: theme.transitions.duration.complex }
            ),
        },
        logo: {
            pointerEvents: 'none',
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            right: 0,
            top: -2,
        },
        switch: {
        },
    }
});


const NavBar = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [miniInputActive, setMiniInputActive] = useState(false);
    let location = useLocation();
    const [{ printing }] = useLayout();

    const theme = useTheme();
    const classes = useStyles(0);
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const xl = useMediaQuery(theme.breakpoints.only('xl'));

    const isCalculatorPage = matchPath(location.pathname, CALCULATOR_LINK);

    // const handleScroll = () => {
    //     setOffset(window.pageYOffset);
    // }

    const handleDrawerToggle = (e, open) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setMobileOpen(open);
    }

    const redirect = (url) => {
        history.push(url);
        window.location.replace(url);
    }

    const SCROLL_THRESHOLD = 200;
    useEffect(() => {
        const handleScroll = () => {
          const element_start = document.getElementById(ID_SUMMARY);
          const element_end = document.getElementById(ID_MORE_INFO);
          if (element_start) {
            const rect_start = element_start.getBoundingClientRect();
            //  the end won't exist until it has been rendered
            const rect_end = element_end ? element_end.getBoundingClientRect() : { y: SCROLL_THRESHOLD };
            //const rect_end = { y: SCROLL_THRESHOLD }
            // Check if the y position of the element is less than 100

            console.log("show:", (rect_start.y < SCROLL_THRESHOLD && rect_end.y >= SCROLL_THRESHOLD))
            setMiniInputActive(rect_start.y < SCROLL_THRESHOLD && rect_end.y >= SCROLL_THRESHOLD);
          }
        };
        window.addEventListener('scroll', handleScroll);
        // Initial check in case the element is already in the desired position
        handleScroll();
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [ID_SUMMARY, ID_MORE_INFO, SCROLL_THRESHOLD]);


    const style = {
        backgroundImage: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
        overflow:`hidden`,
    };

    const menu = [{
        label: "CALCULATOR",
        icon: <KeyboardIcon />,
        link: CALCULATOR_LINK,
    },
    {
        label: "TAX INFO",
        icon: <HelpIcon />,
        link: TAX_INFO_LINK,
    },

    {
        label: "TAX DISTRIBUTION",
        icon: <DonutLargeIcon />,
        link: TAX_DISTRIBUTION_LINK,
    },
    {
        label: "STUDENT LOANS",
        icon: <SchoolIcon />,
        link: STUDENT_LOAN_LINK,
    },
    {
        label: "ORIGINAL CALCULATOR",
        icon: <HistoryIcon />,
        link: OLD_CALCULATOR_LINK,
    },
    {
        label: "MORTGAGE.MONSTER",
        icon: <OutIcon />,
        link: MORTAGE_MONSTER,
    },
    ]

    const auxilleryItems = [{
        label: "Privacy Policy",
        link: PRIVACY_POLICY_LINK,
    },
    {
        label: "Acceptable Use",
        link: ACCEPTABLE_USE_LINK,
    },
    {
        label: "Cookie Policy",
        link: COOKIES_LINK,
    },
    {
        label: "Get in touch",
        link: EMAIL_ADDRESS,
    },
    ]

    const drawer = (
        <div
            className={classes.list}
            role="presentation"
            onClick={(e) => handleDrawerToggle(e, false)}
            onKeyDown={(e) => handleDrawerToggle(e, false)}
        >
            <List>
                {menu.map((item) => (
                    <React.Fragment key={item.label} >
                        <ListItem button onClick={() => redirect(item.link)}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.label} />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                )
                )}
            </List>

            <List>
                {auxilleryItems.map((item) => (
                    <React.Fragment key={item.label} >
                        <ListItem button onClick={() => redirect(item.link)}>
                            <ListItemText primary={item.label} />
                        </ListItem>
                    </React.Fragment>
                )
                )}
            </List>
        </div>
    );


    // active when the page has scrolled
    // let active = offset > (HEADER_HEIGHT + (small ? 300 : 140));

    // const SCROLL_THRESHOLD = 60;
    // const summary = document.getElementById("summary");
    // const rect = summary ? summary.getBoundingClientRect() : { y: SCROLL_THRESHOLD }
    // let active = rect.y < SCROLL_THRESHOLD;
    // active = isCalculatorPage && !xl ? active : false;

    const ToolbarPadding = small ? miniInputActive ? 0 : 20 : 20;
    const logoPadding = small ? miniInputActive ? 0 : 16 : 16;
    const logoScale = small ? 'scale(0.75)' : 'scale(1.0)';


    return (
        <AppBar position="absolute" elevation={0} className={classes.appBar} style={style}  >
            <Toolbar className={classes.nav} style={{ cursor: 'pointer', padding: 0, paddingTop: ToolbarPadding, paddingBottom: ToolbarPadding, minHeight: 0 }} onClick={() => redirect(CALCULATOR_LINK)} >


                <div style={{ paddingLeft: 20 }}>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={(e) => handleDrawerToggle(e, true)}
                    >
                        <MenuIcon />
                    </IconButton>
                </div>


                <div className={classes.logo} style={{ paddingTop: logoPadding, transform: logoScale }} >
                    <Logo />
                </div>

                <div style={{ flexGrow: '1' }} />
                {!printing && !small && (
                    <Box style={{ position: "absolute", top: 25, right: 70 }}>
                        <MM className={classes.switch} />
                    </Box>
                )}
                {!printing && (
                    <Box style={{ position: "absolute", top: 25, right: 20 }}>
                        <ThemeSwitch className={classes.switch} />
                    </Box>
                )}
            </Toolbar>
            {!printing && (
                <MiniInput active={miniInputActive} />
            )}
            {!printing && (
                <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={(e) => handleDrawerToggle(e, false)}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            )}



        </AppBar>
    )

}


export default NavBar;