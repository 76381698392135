import md5 from 'md5';
import { API_PATH } from '../constants'
import { cleanCalculatorData } from '../core/calculatorUtils';

export function catchEm(promise) {
  return promise.then(data => [null, data])
    .catch(err => [err]);
}

export async function sendMail(data) {

  const { name, address, message} = data;
  const key = md5(`~email~${name}~${address}~${message}~`);
  let uri = `${API_PATH}/email/?name=${name}&address=${address}&message=${message}&key=${key}`;

  const headers = {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: "cors",
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit', // include, *same-origin, omit
    headers: {
      'Content-Type': 'text/plain;charset=UTF-8',
    },
  }

  const [err, response] = await catchEm(fetch(uri, headers));
  if (err) {
    return "Error";
  }

  // parse and align data with current initial props
  const [err_json, response_json] = await catchEm(response.text());
  if (err_json) {
    return "Error";
  }

  // response_json = JSON.parse(response_json);
  return response_json;
}


export async function fetchPermalink(id) {

  if (!id) return false;

  // console.log("Fetching permalink...", id);

  const key = md5(`~id~${id}~`);

  let uri = `${API_PATH}/id/${id}/${key}`;
  const headers = {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: "cors",
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit', // include, *same-origin, omit
    headers: {
      'Content-Type': 'text/plain;charset=UTF-8',
    },
  }

  var response = await fetch(uri, headers);

  // parse and align data with current initial props
  let data = await response.json();
  data = JSON.parse(data);
  data = cleanCalculatorData(data);
  return data;
}


export async function createPermalink(data) {

  const clean = cleanCalculatorData(data);
  const data_str = JSON.stringify(clean);
  const key = md5(`~save~${data_str}~`);

  let uri = `${API_PATH}/save?data=${data_str}&key=${key}`;
  const headers = {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: "cors",
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit', // include, *same-origin, omit
    headers: {
      'Content-Type': 'text/plain;charset=UTF-8',
    },
  }

  var response = await fetch(uri, headers);
  let responseJson = await response.text()
  return responseJson;
}